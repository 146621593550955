import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCourseCompletion = createAsyncThunk(
  "courseContentInfo/courseCompletion",
  async ({
    courseId,
    classid,
    financialYear,
    userinfoObject,
    nToken,
  }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
        {
          courseid: courseId,
          classid: classid,
          teacherid: userinfoObject[0].id,
          financial_year: financialYear,
          schoolcode: userinfoObject[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const courseCompletionSlice = createSlice({
  name: "courseContentInfo",
  initialState: {
    courseCompletion: [],
    courseCompletionLoading: false,
    courseCompletionError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseCompletion.pending, (state) => {
        state.courseCompletionLoading = true;
        state.courseCompletionError = null;
      })
      .addCase(fetchCourseCompletion.fulfilled, (state, action) => {
        state.courseCompletion = action.payload;
        state.courseCompletionLoading = false;
        state.courseCompletionError = null;
      })
      .addCase(fetchCourseCompletion.rejected, (state, action) => {
        state.courseCompletionLoading = false;
        state.courseCompletionError = action.error.message;
      });
  },
});

export default courseCompletionSlice.reducer;
