import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchActivityCompletion = createAsyncThunk(
  "activityCompletion/fetchActivityCompletion",
  async ({ courseId, userid, department, nToken, institution, academic_year }) => {
    try {
      const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/viewActivityCompletion`;
      const response = await axios.post(
        reportDataURL,
        {
          course_id: courseId,
          userid: userid,
          department: department,
          schoolcode : institution,
          academic_year: academic_year,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const activityCompletionSlice = createSlice({
  name: "activityCompletion",
  initialState: {
    activityCompletiondata: [],
    activityCompletionloading: false,
    error: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityCompletion.pending, (state) => {
        state.activityCompletionloading = true;
        state.error = null;
      })
      .addCase(fetchActivityCompletion.fulfilled, (state, action) => {
        state.activityCompletiondata = action.payload;
        state.activityCompletionloading = false;
        state.error = null;
      })
      .addCase(fetchActivityCompletion.rejected, (state, action) => {
        state.activityCompletionloading = false;
        state.error = action.error.message;
      });
  },
});

export default activityCompletionSlice.reducer;
