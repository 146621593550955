import axios from "axios";
import React, { useState } from "react";
import "./QuizAttemptSummary.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuizSummaryList from "./QuizSummaryList";
import Loader from "../../_Components/Utils/loader/Loader";
import MainCard from "../../_Components/Common/Cards/MainCard";

const QuizAttemptSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizSubmited, setQuizSubmited] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [SearchParams] = useSearchParams();

  const attemptId = SearchParams.get("id");
  let isTimeup = SearchParams.get("isTimeup");
  console.log(isTimeup);

  const submitAttemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_process_attempt&moodlewsrestformat=json&attemptid=${attemptId}&finishattempt=1`;

  const sumbitHandler = () => {
    setIsLoading(true);
    setQuizSubmited(true);
    axios.get(submitAttemptURL).then((response) => {
      setIsLoading(false);
      navigate(-4);
    });
  };

  let quizSubmitContent = ``;

  if (quizSubmited) {
    if (isLoading) {
      quizSubmitContent = <Loader />;
    } else {
      quizSubmitContent = "Quiz Submitted Successfully";
    }
  }
  return (
    <MainCard cardTitle={"Assessment"}>
      {quizSubmitContent}
      {!quizSubmited && (
        <QuizSummaryList
          attId={attemptId}
          sumbitHandler={sumbitHandler}
          isLoading={isLoading}
        />
      )}
    </MainCard>
  );
};
export default QuizAttemptSummary;
