
import {
  Box,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
// import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import ProfileBox from "../../userProfile/ProfileBox";
import ProfileImg from "../../userProfile/ProfileImg";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import ChangePassword from "../../ChangePassword/ChangePassword";
import { CenterButtonBoxArea } from "../../../_Components/Common/Buttons/CustomBtns";
import { languages } from "../../../App";
import StudentMarksReport from "./StudentMarksReport";

function ReportProfile() {
  let curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState("");
  const [schoolName, setSchoolName] = useState(null);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userinfoObject[0].id;
  const username = userinfoObject[0].id;
  const [saveImg, setSaveImg] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const teacher = userinfoObject[0].department.toLowerCase() === "pedagogy";
  const student = userinfoObject[0].department.toLowerCase() === "student";

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => {
        setProfileInfo(response.data.info);
      });

    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );

        const selectedSchoolInfo = response.data.info.find(
          (data) => data.id === Number(userinfoObject[0]?.institution)
        );
        setSchoolName(selectedSchoolInfo);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const [profileImgInfo, setProfileImgInfo] = useState(
    userinfoObject[0]?.profileimageurl
  );
  const handleUpdateImg = ({ updatedIMG }) => {
    setProfileImgInfo(updatedIMG);
  };

  const handleImgUpload = () => {
    setSaveImg(true);
  };
  const handleEditing = () => {
    setIsEditing(false);
  };
  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage={true}
        cardTitle="Holistic Progress Card"
        mt='15px'
      >
        <Box className="profileBox">
          <Grid container className="profiler" sx={{ textAlign: { xs: 'center', md: 'left' }, gap: { xs: '0', sm: 2 }, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
            <Grid item lg={1.4} md={1.9} sm={3} xs={12} className="imgBox">
              <ProfileImg 
              
                isEditing={isEditing}
                profileImgInfo={profileImgInfo}
                userId={userId}
                onImgUpload={handleImgUpload}
                username={username}
                onUpdatedProfileImgUrl={handleUpdateImg}
                handleEditing={handleEditing}
              />

            </Grid>
            <Grid item lg={10.6} md={10.1} sm={9} xs={12} className="imgBox">
              <Box className="profileInformation" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                <Typography variant="h4" className="heading">
                  {profileInfo[0]?.firstname} {profileInfo[0]?.lastname}&nbsp;
                  <span className="subHeading">({teacher ? "Teacher" : userinfoObject[0].department})</span>
                </Typography>
                <Typography variant="h5">School: {schoolName?.name}</Typography>
                <Grid container mt={1}>
                  <Grid container className="profileInfo" spacing={2}>
                    {student && (
                      <ProfileBox >
                        <FormLabel>Roll number</FormLabel>
                        <Typography>{profileInfo[0]?.idnumber}</Typography>
                      </ProfileBox>
                    )}

                    <ProfileBox >
                      <FormLabel>{curLanguage.username}</FormLabel>
                      <Typography>{userinfoObject[0].username}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>{curLanguage.Emailaddress}</FormLabel>
                      <Typography>{userinfoObject[0].email}</Typography>
                    </ProfileBox>

                    <ProfileBox>
                      <FormLabel>Gender</FormLabel>
                      <Typography>{profileInfo[0]?.gender}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>Primary Contact</FormLabel>
                      <Typography display={'flex'} alignItems={'center'} gap={.5}><Box className="country-cd">+91</Box>{profileInfo[0]?.phone1}</Typography>
                    </ProfileBox>

                    <ProfileBox>
                      <FormLabel>Alternate Contact</FormLabel>
                      <Typography>{profileInfo[0]?.phone2}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>Address</FormLabel>
                      <Typography>{profileInfo[0]?.address}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>{curLanguage.Citytown}</FormLabel>
                      <Typography>{profileInfo[0]?.city}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>{curLanguage.state}</FormLabel>
                      <Typography>{profileInfo[0]?.state}</Typography>
                    </ProfileBox>
                    <ProfileBox>
                      <FormLabel>{curLanguage.country}</FormLabel>
                      <Typography>
                        {profileInfo[0]?.country === "IN" && "India"}
                      </Typography>
                    </ProfileBox>
                  </Grid>
                  {/* <Grid item md={4} sm={12} xs={12} className="flex-con-mb">
                      <FormLabel sx={{paddingRight:1}}>Last Access:</FormLabel>
                      <Typography>{profileInfo[0]?.lastaccess.replaceAll('-', ' ')}</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="flex-con-mb">
                      <FormLabel sx={{paddingRight:1}}>Joined on:</FormLabel>
                      <Typography>{profileInfo[0]?.timecreated.replaceAll('-', ' ')}</Typography>
                    </Grid> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <StudentMarksReport />
      </MainCard>
      
    </Box>
  );
}
export default ReportProfile;
