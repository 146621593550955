import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCourseContentInfo = createAsyncThunk(
  "coursecontent/courseContentInfo",
  async ({ courseId, token }) => {
    const get_Course_Content = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_course_get_contents_info&moodlewsrestformat=json&courseid=${courseId}`;
    const response = await axios.get(get_Course_Content);
    return response.data;
  }
);

const courseContentInfoSlice = createSlice({
  name: "coursecontent",
  initialState: {
    courseContentInfo: [],
    courseContentInfoLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseContentInfo.pending, (state) => {
        state.courseContentInfoLoading = true;
        state.error = null;
      })
      .addCase(fetchCourseContentInfo.fulfilled, (state, action) => {
        state.courseContentInfoLoading = false;
        state.courseContentInfo = action.payload || [];
        state.error = null;
      })
      .addCase(fetchCourseContentInfo.rejected, (state, action) => {
        state.courseContentInfoLoading = false;
        state.error = action.error.message;
      });
  },
});

export default courseContentInfoSlice.reducer;
