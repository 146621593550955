import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { montArr } from "../../../_Components/Common/MonthArray";
import { AiOutlineEdit } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";
import Scrollbar from "react-scrollbars-custom";

const ViewFeeStructure = () => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const [feeStructure, setFeeStructure] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
    axios
      .get(baseURL, {
        headers: { Authorization: `Bearer ${nToken}` },
      })
      .then((response) => setClassData(response.data.class_section));
  }, []);

  useEffect(() => {
    if (selectedClass.length > 0) {
      const fetchFeeStructure = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/FeeStructureView?schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}&class_name=${selectedClass}`,
            {
              headers: { Authorization: `Bearer ${nToken}` },
            }
          );
          setFeeStructure(response.data.fee_strcture_data || []);
        } catch (error) {
          console.log(error);
        }
      };
      fetchFeeStructure();
    }
  }, [selectedClass]);

  const onHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClass(typeof value === "string" ? value.split(",") : value);
  };

  const uniqueClassData = Array.from(
    new Map(classData.map((data) => [data.class, data])).values()
  );

  const feeData = feeStructure?.flatMap((item) =>
    item?.fee_type
      ? Object.entries(item.fee_type).map(([month, fees]) => ({
          class: item.class,
          month: montArr[month] || "Invalid Month",
          feeType: Object.entries(fees).map(([type, amount]) => (
            <div key={type}>
              <b>{type}</b>: {amount}
            </div>
          )),
        }))
      : []
  ) || [];

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="Selected-class-label">Class</InputLabel>
        <Select
          labelId="Selected-class-label"
          id="Selected-class"
          multiple
          value={selectedClass}
          onChange={onHandleChange}
          input={<OutlinedInput label="Class" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {uniqueClassData.map((data) => (
            <MenuItem key={data.id} value={data.class}>
              <Checkbox checked={selectedClass?.includes(data.class)} />
              <ListItemText primary={data.class} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Scrollbar style={{ width: "100%", height: 500 }}>
        <Box>
          {!selectedClass.length ? (
            <Typography>Please select a class</Typography>
          ) : feeData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Class</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.class}</TableCell>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.feeType}</TableCell>
                    <TableCell>
                      <IconButton>
                        <AiOutlineEdit color="profileButton.main" />
                      </IconButton>
                      <IconButton>
                        <IoTrashOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>Structure is not created</Typography>
          )}
        </Box>
      </Scrollbar>
    </>
  );
};

export default ViewFeeStructure;

