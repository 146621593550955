import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { montArr } from "../../../../_Components/Common/MonthArray";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const ViewDiscountForm = ({ trigger, onDiscountEdit }) => {
  const [discount, setDiscount] = useState("");
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchDiscount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/viewFeeDiscount?schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}`,
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      setDiscount(response.data.class_fee_structure);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDiscount();
  }, [trigger]);

  const handleDeleteDiscount = async (data) => {
    await axios.post(
      `${process.env.REACT_APP_nodewsPrefix}/classFeeDiscountDelete`,
      {
        class_fee_discountID: data,
      },
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      }
    );
    await fetchDiscount();
  };

  const handleDiscountEdit = (data) => {
    onDiscountEdit(data);
  };

  return (
    <Box>
      {discount.length <= 0 ? (
        ""
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Class</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Months</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discount?.map((data) => (
              <TableRow key={data.class_fee_structureID}>
                <TableCell>{data.class}</TableCell>
                <TableCell>{data.time_period}</TableCell>
                <TableCell>
                  {data.discount}
                  {data.discount_type === "Percentage" ? "%" : "Rs"}
                </TableCell>
                <TableCell>
                  {data.discount_period
                    .split(",")
                    .map((index) => montArr[index - 1])
                    .join(", ")}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDiscountEdit(data)}>
                    <FiEdit size={20} />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleDeleteDiscount(data.class_fee_structureID)
                    }
                  >
                    <RiDeleteBin6Line size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default ViewDiscountForm;
