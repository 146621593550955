import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import "./RecentItems.scss";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

import { languages } from "../../App";
import CardSecondary from "../../_Components/Common/Cards/CardSecondary";
import ProcessText from "../../_Components/Common/ProceessText";

function RecentItems(props) {
  const token = localStorage.getItem("token");

  const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=block_recentlyaccesseditems_get_recent_items&moodlewsrestformat=json`;
  const [recentItems, setRecentItems] = useState([]);
  const [cmid, setCmid] = useState();
  const [isLoading, setIsLoading] = useState(true);
  let curLanguage = useContext(languages);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setRecentItems(response.data);
      const cmids = response.data.map((ids) => {
        return ids.cmid;
      });

      const recentURL =
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/custom_ws/getModuleIntance.php?cmid=[${cmids}` +
        "]";

      axios.get(recentURL).then((response) => {
        setCmid(response.data);
        setIsLoading(false);
      });
    });
  }, []);

  let tempRecentItemDiv;

  if (recentItems.length > 0) {
    tempRecentItemDiv = recentItems.filter((item) => {
      return item.modname === "scorm";
    });
  } else {
    tempRecentItemDiv = [];
  }

  if (tempRecentItemDiv.length < 5) {
    tempRecentItemDiv.push();
  }
  let recentItemsDiv =
    tempRecentItemDiv.length > 0 &&
    tempRecentItemDiv.slice(0, 4).map((item, indx) => (
      <React.Fragment key={indx}>
        {item && item.modname === "scorm" ? (
          <NavLink
            key={indx}
            to={{
              pathname: "/GetCourseContent/modAccessInformation",
              search: `?cmid=${item.cmid}&courseID=${item.courseid}&modname=${item.modname}&title=${item.name}`,
            }}
            className="navLinks"
          >
            <Box className="customBox">
              <Box className="imgSec">
                <img
                  src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/activity.svg`}
                  alt="recent_icons"
                  width={30}
                />
              </Box>
              <Box>
                <Typography
                  className="title"
                  sx={{ color: "textsColor.color" }}
                >
                  {item.name}
                </Typography>
                <Box sx={{ color: "recentSub.main" }} className="subTitle">
                  Class: {ProcessText(item.coursename).number} &nbsp; &nbsp;
                  Subject: {ProcessText(item.coursename).text}
                </Box>
              </Box>
            </Box>
          </NavLink>
        ) : (
          ""
        )}
      </React.Fragment>
    ));

  // let recentItemsDivSecond =
  //   recentItems.length > 0 &&
  //   recentItems.slice(0, 5).map((item, indx) => (
  //     <React.Fragment key={indx}>
  //       {item && item.modname === "quiz" ? (
  //         <NavLink
  //           key={indx}
  //           to={"/GetCourseContent/modAccessInformation"}
  //           state={{
  //             cmid: item.cmid,
  //             courseId: item.courseid,
  //             title: item.name,
  //             // classid: location.state.classid,
  //             modname: item.modname,
  //           }}
  //           className="navLinks"
  //         >
  //           <div className="customBox">
  //             <Box className="imgSec">
  //               <img
  //                 src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/assignment.svg`}
  //                 alt="recent_icons"
  //                 width={30}
  //               />
  //             </Box>
  //             <Box>
  //               <Typography
  //                 className="title"
  //                 sx={{ color: "textsColor.color" }}
  //               >
  //                 {item.name}
  //               </Typography>
  //               <Box sx={{ color: "recentSub.main" }} className="subTitle">
  //                 {item.coursename}
  //               </Box>
  //             </Box>
  //           </div>
  //         </NavLink>
  //       ) : (
  //         ""
  //       )}
  //     </React.Fragment>
  //   ));

  return (
    <Box className="rightWrapperSec">
      <CardSecondary curLanguage={curLanguage.recentactivities}>
        {recentItemsDiv.length > 0 ? (
          // <>{isLoading ? <Loader /> : recentItemsDiv}</>
          recentItemsDiv
        ) : (
          <Box className="navLinks" style={{ textAlign: "center" }}>
            <Typography>No Recent Activity</Typography>{" "}
          </Box>
        )}
      </CardSecondary>
      {/* Omiting it for now as per the discussion*/}
      {/* <CardSecondary curLanguage={curLanguage.recentassignments}>
        {recentItemsDivSecond > 0 ? (
          <> {isLoading ? <Loader /> : recentItemsDivSecond}</>
        ) : (
          <Box className="navLinks" textAlign="center"><Typography>No Recent Assignments</Typography></Box>
        )}
      </CardSecondary> */}
    </Box>
  );
}

export default RecentItems;
