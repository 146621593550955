// import React from 'react'

// function StudentMarksReport() {
//   return (
//     <div>StudentMarksReport</div>
//   )
// }

// export default StudentMarksReport
import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from "@mui/material";

const initialData = [
  { course_name: "English", term1: "85.00", term2: "92.00" },
  { course_name: "Mathematics", term1: "88.00", term2: "94.00" },
  { course_name: "Science", term1: "90.00", term2: "89.00" },
  { course_name: "Social Studies", term1: "82.00", term2: "87.00" },
];

const StudentMarksReport = () => {
  const [selectedTerm, setSelectedTerm] = useState("term1");
  const [showAll, setShowAll] = useState(false);

  const filteredData = showAll
    ? initialData
    : initialData.map((row) => ({
        course_name: row.course_name,
        marks: row[selectedTerm],
      }));

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Report Card - Student Name
          </Typography>
          {/* Select Term */}
          <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", gap: 2 }}>
            <Select
              value={selectedTerm}
              onChange={(e) => setSelectedTerm(e.target.value)}
              displayEmpty
              sx={{ minWidth: 120 }}
              disabled={showAll}
            >
              <MenuItem value="term1">Term 1</MenuItem>
              <MenuItem value="term2">Term 2</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAll(true)}
            >
              View Full Report
            </Button>
          </Box>

          {/* Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Subject</strong></TableCell>
                  {showAll ? (
                    <>
                      <TableCell><strong>Term 1 Marks</strong></TableCell>
                      <TableCell><strong>Term 2 Marks</strong></TableCell>
                    </>
                  ) : (
                    <TableCell><strong>Marks</strong></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.course_name}</TableCell>
                    {showAll ? (
                      <>
                        <TableCell>
                          <Typography>{row.term1}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{row.term2}</Typography>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell>
                        <Typography>{row.marks}</Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Remarks Section */}
          {showAll && (
            <>
              <Divider sx={{ marginY: 2 }} />
              <Typography variant="h6" gutterBottom>
                Remarks
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Outstanding performance in academics and co-curricular activities.
              </Typography>

              {/* Signatures */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    Teacher's Signature
                  </Typography>
                  <Box
                    sx={{
                      width: 200,
                      height: 50,
                      border: "1px solid #000",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    Principal's Signature
                  </Typography>
                  <Box
                    sx={{
                      width: 200,
                      height: 50,
                      border: "1px solid #000",
                    }}
                  />
                </Box>
              </Box>
            </>
          )}

          {/* Save Button */}
          {/* {showAll && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button variant="contained" color="primary" onClick={() => console.log("Report viewed!")}>
                Done
              </Button>
            </Box>
          )} */}
        </CardContent>
      </Card>
      </>
  );
};

export default StudentMarksReport;

