import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import DeveloperPermission from "./DeveloperPermission";

export default function Setting() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <>
      <MainCard cardTitle={"Setting"}>
        <Grid container spacing={2}>
          {userInfo[0]?.department.toLowerCase() === "developer" ? (
            <DeveloperPermission />
          ) : (
            <>
              <Grid item>
                <Link
                  rel="preload"
                  to={{ pathname: "/permission" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Permission For School
                </Link>
              </Grid>
              {/* <Grid item>
                <Link
                  to={{ pathname: "/assignrole" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Assign Role
                </Link>
              </Grid> */}
              <Grid item>
                <Link
                  to={{ pathname: "/attendanceFormat" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Attendance Format
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={{ pathname: "/assignTimeTable" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Create Timetable
                </Link>
              </Grid>
              
              <Grid item>
                <Link
                  to={{ pathname: "/Fee-Structure" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                   Create Fee Structure
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={{ pathname: "/adminReport" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                   Report Card Format
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </MainCard>
    </>
  );
}
