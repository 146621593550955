import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";

const ExamSheetCreator = ({ onUpdatingEvent }) => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  const [stdClassInfo, setStdClassInfo] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examname, setExamname] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [subjectsVal, setSubjectsVal] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const [formValues, setFormValues] = useState([
    {
      stdClass: "",
      stdsection: "",
      subject: "",
      date: null,
      duration: "",
    },
  ]);

  const handleInputData = (index, event) => {
    const { name, value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;
    console.log(sections, "sectioninput");
    if (name === "stdsection" && value === sections) {
      newFormValues[index][name] = sections;
    } else {
      newFormValues[index][name] = value;
    }

    setFormValues(newFormValues);

    if (name === "stdClass" || name === "stdsection") {
      const selectedClass =
        name === "stdClass" ? value : newFormValues[index].stdClass;
      const selectedSection =
        name === "stdsection" ? value : newFormValues[index].stdsection;

      if (selectedClass && selectedSection) {
        const selectedClassInfo = stdClassInfo.find(
          (item) =>
            item.class === parseInt(selectedClass) &&
            item.section === selectedSection
        );
        if (selectedClassInfo) {
          fetchSubjects(selectedClassInfo.group_name, index);
        }
      } else if (selectedClass) {
        const selectedClassInfo = stdClassInfo.find(
          (item) => item.class === parseInt(selectedClass)
        );
        if (selectedClassInfo) {
          fetchSubjects(selectedClassInfo.group_name, index);
        }
      }
    }
  };

  const handleDateChange = (index, date) => {
    const newFormValues = [...formValues];
    newFormValues[index].date = date;
    setFormValues(newFormValues);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        const data = response.data.class_section;
        setStdClassInfo(data);
        const uniqueClasses = [...new Set(data.map((item) => item.class))];
        setClasses(uniqueClasses);
        const uniqueSections = [...new Set(data.map((item) => item.section))];
        setSections(uniqueSections);
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchSubjects = async (groupName, index) => {
    try {
      const subjectBaseURL = `${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`;
      const response = await axios.post(
        subjectBaseURL,
        {
          // class_name: formValues[index].stdClass + formValues[index].stdsection,
          // schoolcode: userinfo[0]?.institution,
          group_name: groupName,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const newFormValues = [...formValues];
      newFormValues[index].subjects = response.data.subject_data;
      setSubjectsVal(newFormValues[index].subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const [isDisabled, setIsDisabled] = useState(true);

  // Check form completeness
  useEffect(() => {
    const checkFormCompleteness = () => {
      return formValues.every(
        (formValue) =>
          formValue.stdClass &&
          formValue.stdsection &&
          formValue.subject &&
          formValue.date &&
          formValue.duration
      );
    };
  
    setIsDisabled(!checkFormCompleteness());
  }, [formValues]); // Recalculate when formValues change

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setErrors([]);
    setLoading(false);
    const aggregatedData = formValues.reduce(
      (acc, formValue) => {
        const classIndex = acc.stdClass.indexOf(parseInt(formValue.stdClass));
        console.log(formValue.stdsection, "classIndex");
        if (formValue.stdsection === sections) {
          const allSections = sections;

          if (classIndex === -1) {
            acc.stdClass.push(parseInt(formValue.stdClass));
            acc.section.push(allSections);
            acc.courseid.push(allSections.map(() => formValue.subject));
            acc.duration.push(allSections.map(() => formValue.duration));
            acc.date.push(allSections.map(() => formValue.date.$D));
            acc.hour.push(allSections.map(() => formValue.date.$H));
            acc.minute.push(allSections.map(() => formValue.date.$m));
            acc.month.push(allSections.map(() => formValue.date.$M + 1));
          } else {
            acc.section[classIndex] = allSections;
            acc.courseid[classIndex] = allSections.map(() => formValue.subject);
            acc.duration[classIndex] = allSections.map(
              () => formValue.duration
            );
            acc.date[classIndex] = allSections.map(() => formValue.date.$D);
            acc.hour[classIndex] = allSections.map(() => formValue.date.$H);
            acc.minute[classIndex] = allSections.map(() => formValue.date.$m);
            acc.month[classIndex] = allSections.map(
              () => formValue.date.$M + 1
            );
          }
        } else {
          if (classIndex === -1) {
            acc.stdClass.push(parseInt(formValue.stdClass));
            acc.section.push([formValue.stdsection]);
            acc.courseid.push([formValue.subject]);
            acc.duration.push([formValue.duration]);
            acc.date.push([formValue.date.$D]);
            acc.hour.push([formValue.date.$H]);
            acc.minute.push([formValue.date.$m]);
            acc.month.push([formValue.date.$M + 1]);
          } else {
            acc.section[classIndex].push(formValue.stdsection);
            acc.courseid[classIndex].push(formValue.subject);
            acc.duration[classIndex].push(formValue.duration);
            acc.date[classIndex].push(formValue.date.$D);
            acc.hour[classIndex].push(formValue.date.$H);
            acc.minute[classIndex].push(formValue.date.$m);
            acc.month[classIndex].push(formValue.date.$M + 1);
          }
        }
        return acc;
      },
      {
        title: examname,
        description: "",
        repeats: 0,
        duration: [],
        stdClass: [],
        section: [],
        courseid: [],
        eventtype: "course",
        year: String(formValues[0].date.$y),
        month: [],
        date: [],
        hour: [],
        minute: [],
        token: token,
        instituteid: userinfo[0]?.institution,
        url: `${process.env.REACT_APP_wsPrefix}/`,
      }
    );
  
    const createEventURL = `${process.env.REACT_APP_nodewsPrefix}/createExamEvent`;
    try {
      setLoading(true);
      await axios.post(createEventURL, aggregatedData, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      alert("Exam events created successfully");
      setFormValues([
        {
          stdClass: "",
          stdsection: "",
          subject: "",
          date: null,
          duration: "",
        },
      ]);
      setExamname("");
      onUpdatingEvent();
      setLoading(false);
    } catch (error) {
      console.error("Error while creating events:", error);
    }
  };

  const addRow = () => {
    setFormValues([
      ...formValues,
      {
        stdClass: "",
        stdsection: "",
        subject: "",
        date: null,
        duration: "",
      },
    ]);
  };

  const removeRow = (index) => {
    const newFormValues = formValues.filter((_, i) => i !== index);
    setFormValues(newFormValues);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          <Box mt={2}>
            <TextField
              id="examname"
              label="Exam Name"
              variant="outlined"
              name="examname"
              value={examname}
              onChange={(e) => setExamname(e.target.value)}
              fullWidth
              required
            />
            {formValues.map((formValue, index) => (
              <Grid container spacing={1} mt={1} key={index}>
                <Grid item md={2}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="stdClass-label">Class</InputLabel>
                    <Select
                      labelid="stdClass-label"
                      id="stdClass"
                      name="stdClass"
                      value={formValue.stdClass}
                      onChange={(event) => handleInputData(index, event)}
                      label="Class"
                    >
                      {classes.map((cls) => (
                        <MenuItem key={cls} value={cls}>
                          {cls}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="stdsection-label">Section</InputLabel>
                    <Select
                      labelid="stdsection-label"
                      id="stdsection"
                      name="stdsection"
                      value={
                        formValue.stdsection === "All"
                          ? "All Section"
                          : formValue.stdsection
                      }
                      onChange={(event) => handleInputData(index, event)}
                      label="Section"
                    >
                      <MenuItem value={sections}>All Sections</MenuItem>
                      {sections.map((section) => (
                        <MenuItem key={section} value={section}>
                          {section}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="subject-label">Subject</InputLabel>
                    <Select
                      labelid="subject-label"
                      id="subject"
                      name="subject"
                      value={formValue.subject}
                      onChange={(event) => handleInputData(index, event)}
                      label="Subject"
                    >
                      {formValue.subjects?.map((option) => (
                        <MenuItem key={option.courseid} value={option.courseid}>
                          {option.course_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      label="Date"
                      value={formValue.date}
                      onChange={(date) => handleDateChange(index, date)}
                      disablePast
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          error={!!errors[index]?.date} // Show red border if error exists
                          helperText={errors[index]?.date || ""} // Display error message
                        />
                      )}
                      format="DD-MM-YYYY"
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={2}>
                  <TextField
                    id="duration"
                    label="Duration (min)"
                    variant="outlined"
                    name="duration"
                    type="number"
                    value={formValue.duration}
                    onChange={(event) => handleInputData(index, event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={1}>
                  {formValues.length > 1 && (
                    <IconButton
                      aria-label="Delete row"
                      sx={{ padding: "1px" }}
                      onClick={() => removeRow(index)}
                    >
                      <AiOutlineMinusCircle fontSize={26} />
                    </IconButton>
                  )}

                  {index === formValues.length - 1 && (
                    <IconButton
                      aria-label="Add row for other class or subject"
                      onClick={addRow}
                    >
                      <AiOutlinePlusCircle fontSize={26} color="primary" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box textAlign="center" mt={2}>
            <PrimaryBgBtn type="submit" disabled={isDisabled}>Submit</PrimaryBgBtn>
          </Box>
        </>
      )}
    </form>
  );
};

export default ExamSheetCreator;
