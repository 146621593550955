import ReactEcharts from "echarts-for-react";
function SectionWiseChart() {
    var option;

    // There should not be negative values in rawData
    const rawData = [[100]];
    const totalData = [];
    for (let i = 0; i < rawData[0].length; ++i) {
        let sum = 0;
        for (let j = 0; j < rawData.length; ++j) {
            sum += rawData[j][i];
        }
        totalData.push(sum);
    }
    const grid = {
        left: 100,
        right: 100,
        top: 50,
        bottom: 50
    };
    const series = ['Section Wise Bar Graph'].map((name, sid) => {
        return {
            name,
            type: 'bar',
            stack: 'total',
            barWidth: '60%',
            label: {
                show: true,
                formatter: (params) => 50 + '%'
            },
            data: rawData[sid].map((d, did) =>
                totalData[did] <= 0 ? 0 : d / totalData[did]
            )
        };
    });
    option = {
        legend: {
            selectedMode: false
        },
        grid,
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: ['Mon']
        },
        series
    };

    // option && myChart.setOption(option);
    return <ReactEcharts option={option} />
}

export default SectionWiseChart