import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";

const DownloadCertificate = ({ certificates }) => {
  const [fullViewCertificate, setFullViewCertificate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CertificateImg, setCertificateImg] = useState();
  const [open, setOpen] = useState(false);
  const nToken = localStorage.getItem("nToken");

  const fullViewCertificateHandler = (url) => {
    setOpen(true);
    setFullViewCertificate(true);
    setCertificateImg(url);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadCertificate = async (url, id) => {
    let resp = await axios.get(
      `${process.env.REACT_APP_nodewsPrefix}/downloadCertificate?certificateid=${id}`,
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      }
    );

    let filename = `${process.env.REACT_APP_nodewsPrefix}${resp.data.zip}`;
    const link = document.createElement("a");
    link.href = filename;
    link.target = "_blank";
    link.download = filename.replace(
      `${process.env.REACT_APP_nodewsPrefix}`,
      ""
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(certificates, "certificatescertificates");
  return (
    <>
      <Grid container spacing={2}>
        {certificates?.length ? (
          certificates?.map((val, id) => {
            return (
              <Grid item md={3} key={id}>
                <Card className="certificateCard">
                  <CardContent
                    className="certificateContent"
                    onClick={() =>
                      fullViewCertificateHandler(
                        process.env.REACT_APP_nodewsPrefix + val.certficateURL
                      )
                    }
                  >
                    <img
                      width="100%"
                      alt="Certificate"
                      src={
                        process.env.REACT_APP_nodewsPrefix + val.certficateURL
                      }
                    />
                  </CardContent>
                  <CardActions sx={{ paddingTop: "0" }}>
                    <Box width={"100%"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: 14 }} color="text.primary">
                          Earned for {val.subject} {val.type}
                        </Typography>

                        <Button
                          size="small"
                          onClick={() =>
                            downloadCertificate(
                              process.env.REACT_APP_nodewsPrefix +
                                val.certficateURL,
                              val.id
                            )
                          }
                          startIcon={<HiOutlineDownload />}
                        >
                          Download
                        </Button>
                      </Box>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {val.timecreated}
                      </Typography>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Box sx={{ p: 4 }}>
            <Typography variant="h4" textAlign="center">
              <b>You have not earned any certificates so far.</b>
            </Typography>
          </Box>
        )}
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent
          sx={{
            color: "popup.popupheadertextcolor",
          }}
        >
          <img
            src={CertificateImg}
            className="certificateImg"
            alt="Certificate"
            width={"100%"}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DownloadCertificate;
