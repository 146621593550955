import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchClassTeacher = createAsyncThunk(
  "classTeacher/fetchClassTeacher",
  async ({ userId, schoolcode, nToken }) => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userId}&schoolcode=${schoolcode}`;
    try {
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      return response.data.rows;
    } catch (error) {
      throw error;
    }
  }
);

export const classTeacherSlice = createSlice({
  name: "classTeacher",
  initialState: {
    classTeacherData: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearAssets: (state) => {
      state.data = null; // Clear the stored data
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchClassTeacher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassTeacher.fulfilled, (state, action) => {
        state.loading = false;
        state.classTeacherData = action.payload;
      })
      .addCase(fetchClassTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearAssets } = classTeacherSlice.actions;
export default classTeacherSlice.reducer;
