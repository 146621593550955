import { configureStore } from "@reduxjs/toolkit";
import classTeacherReducer from "./apiSlice/classTeacherSlice";
import courseContentInfoReducer from "./apiSlice/courseContentSlice";
import courseCompletionSliceReducer from "./apiSlice/courseCompletionSlice";
import activityCompletionSliceReducer from "./apiSlice/activityCompletionSlice";
import classSectionSliceReducer from "./apiSlice/classSectionSlice";

const store = configureStore({
  reducer: {
    classTeacher: classTeacherReducer,
    courseContentInfo : courseContentInfoReducer,
    courseCompletion: courseCompletionSliceReducer,
    activityCompletion: activityCompletionSliceReducer,
    classSectionInfo: classSectionSliceReducer,
  },
});

export default store;
