import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./MyClasses.scss";
import { languages } from "../../App";
import Loader from "../../_Components/Utils/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassTeacher } from "../../redux/apiSlice/classTeacherSlice";

const MyClasses = () => {
  let curLanguage = useContext(languages);
  const courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const [enroledClass, setEnroledClass] = useState([]);
  const dispatch = useDispatch();

  const { classTeacherData: data, loading, error } = useSelector((state) => state.classTeacher);

  useEffect(() => {
    dispatch(
      fetchClassTeacher({
        userId: userinfo[0].id,
        schoolcode: userinfo[0].institution,
        nToken: nToken,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!loading && Array.isArray(data) && data.length > 0) {
      const sortedData = [...data].sort((a, b) => a.id - b.id);
      setEnroledClass(sortedData);  
    }
  }, [data, loading]);

  const getName = (item) => {
    let name = "";
    let imgsrc = "";

    courseinfoObject?.map((val) => {
      if (val.id === item.courseid) {
        name = val.fullname;
        imgsrc = val.overviewfiles[0]?.fileurl.replace("/webservice", "");
      }
    });

    return { name: name, image: imgsrc };
  };


  const courses = enroledClass.map((item) => {
    return (
      <NavLink
        key={item.id}
        to={{
          pathname: "/GetCourseContent",
          search: `?id=${item.courseid}&fullname=${encodeURIComponent(
            getName(item).name
          )}&stdclass=${item.class}&section=${item.section}&classid=${item.id}`,
        }}
        className="navLinks"
        rel="preconnect"
      >
        <Box className="imgSec">
          <img src={getName(item).image} alt="course-img" loading="lazy" />
        </Box>
        <Box className="textArea">
          <Typography
            className="classSec"
            variant="h5"
            sx={{ color: "recentSub.main", textTransform: "capitalize" }}
          >
            {item.class}
            {item.section.charAt(0).toUpperCase()}
          </Typography>
          <Typography
            className="courseName"
            variant="h4"
            sx={{ color: "recentSub.main" }}
            pl={5}
          >
            {getName(item).name}
          </Typography>
        </Box>
        <Box className="overlay">
          <Box className="overlayTxt">
            <Typography className="subject" style={{ fontSize: "20px" }}>
              {getName(item).name}
            </Typography>
            <Typography style={{ fontSize: "2rem" }}>
              <b>{item.class}</b>&nbsp;
              <b>{item.section.toUpperCase()}</b>
            </Typography>
          </Box>
        </Box>
      </NavLink>
    );
  });

  return (
    <Box className="recentSec" sx={{ backgroundColor: "darkblue.color" }}>
      <Typography
        className="recentItemHeader mainHeading"
        sx={{
          color: "recent.titleColor",
          mb: "2",
          backgroundColor: "secondaryColor.main",
        }}
      >
        My Classes
      </Typography>
      <Grid container spacing={1} className="course">
        {loading ? (
          <Loader />  // Display loader while fetching data
        ) : courses.length > 0 ? (
          courses  // Display courses once data is available
        ) : (
          <Box className="noCourse" sx={{ color: "red" }}>
            {curLanguage.norecentcourses} 
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default MyClasses;
