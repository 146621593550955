import React from 'react'
import { usePermissions } from '../../../_Components/Permission/PermissionsContext'
import ReportProfile from './ReportProfile';
import TeacherCard from './TeacherCard';
import AnnualPlanner from '../../Planner/AnnualPlanner';
import ExamSheetCreator from '../../Planner/ExamSheetCreator';
import StudentList from './StudentList';

function Report() {
  const { permissions } = usePermissions();
  return (
    <div>
      {permissions?.report?.read && permissions?.report?.write && <div>
        <TeacherCard/>
       
      </div>
      }
      {permissions?.report?.read && !permissions?.report?.write && <div>
        <ReportProfile/>
      </div>
      }

    </div>


  )
}

export default Report