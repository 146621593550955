import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai";

import "./getCalendarEvent.css";
import "./getCalendarEvent.scss";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { languages } from "../../../App";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextIcon from "../../../_Components/Utils/IconWithText/TextIcon";
import { MdOutlineBook, MdOutlineCalendarMonth } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import FormatDateTime from "./FormatDateTime";
import { HiOutlineCalendar } from "react-icons/hi";

function GetCalendarEvent(props) {
  const nToken = localStorage.getItem("nToken");
  const [calendarEvent, setCalendarEvent] = useState({});
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [saveBtn, setSaveBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const courseDetails = JSON.parse(localStorage.getItem("courseDetails"));
  const { id } = props;
  let curLanguage = useContext(languages);
  const [editedClass, setEditedClass] = useState([]);
  const [editedData, setEditedData] = useState({
    editEventDate: "",
    editEventTime: "",
    editEventName: calendarEvent?.event?.name,
    editDescription: calendarEvent?.event?.description,
    editGroupid: calendarEvent?.event?.groupid,
    editCourseid: calendarEvent?.event?.course?.id,
  });

  const moduleNames = ["scorm", "quiz"];
  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_get_calendar_event_by_id_info&moodlewsrestformat=json&eventid=${id}`;
    axios.get(baseURL).then((response) => {
      setCalendarEvent(response.data);

      setEditedData((prevState) => {
        return {
          ...prevState,
          editEventName: response.data.event?.name,
          editDescription: response.data.event?.description,
        };
      });
    });

    const getStdURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
    axios
      .get(getStdURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setEditedClass(response.data.rows);
      });
  }, [id]);

  const parseHTML = (data) => {
    if (data == "") {
      return "";
    }
    let abc = <div dangerouslySetInnerHTML={{ __html: data }} />;
    return abc;
  };

  //Edited Data
  const handleContentChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };
  const handleDateChange = (newValue) => {
    setEditedData((prevState) => ({
      ...prevState,
      editEventDate: newValue,
    }));
  };

  const handleTimeChange = (newValue) => {
    setEditedData((prevState) => ({
      ...prevState,
      editEventTime: newValue,
    }));
  };

  const filterSubjects = (selectedClass) => {
    const filteredCourses = editedClass.filter(
      (data) => data.group_id === Number(selectedClass)
    );
    return filteredCourses;
  };
  const filterSubjectsName = (selectedClassID) => {
    const filteredCourses = courseDetails.filter(
      (data) => data.id === Number(selectedClassID)
    );
    return filteredCourses;
  };

  const handleSaveEditData = async (
    eventID,
    createdUserId,
    repeatid,
    timestart,
    userType,
    courseID,
    groupID,
    description
  ) => {
    try {
      const existingDate = new Date(timestart * 1000);
      let newDate;
      if (editedData.editEventTime && !editedData.editEventDate) {
        const hours = editedData.editEventTime.$H;
        const minutes = editedData.editEventTime.$m;
        const seconds = editedData.editEventTime.$s;
        newDate = new Date(existingDate);
        newDate.setHours(hours, minutes, seconds, 0);
      } else if (editedData.editEventDate && !editedData.editEventTime) {
        newDate = new Date(editedData.editEventDate);
      } else {
        const hours = editedData.editEventTime.$H;
        const minutes = editedData.editEventTime.$m;
        const seconds = editedData.editEventTime.$s;
        newDate = new Date(editedData.editEventDate);
        newDate.setHours(hours, minutes, seconds, 0);
      }
      const timestamp = newDate.getTime();
      const timestampInSeconds = Math.floor(timestamp / 1000);
      let editDataUrl;
      const baseUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_submit_create_update_form&moodlewsrestformat=json&formdata=id=${eventID}%26eventtype=${userType}`;
      const commonData = `%26userid=${createdUserId}%26_qf__core_calendar_local_event_forms_update=1%26name=${
        editedData.editEventName || calendarEvent?.event?.name
      }%26description=${
        editedData.editDescription || description
      }%26timestart=${timestampInSeconds || timestart}%26repeatid=${
        repeatid || eventID
      }`;

      if (userType === "user") {
        editDataUrl = `${baseUrl}${commonData}`;
      } else {
        const additionalData = `%26courseid=${
          editedData.editCourseid || courseID
        }%26groupid=${editedData.editGroupid || groupID}`;
        editDataUrl = `${baseUrl}${additionalData}${commonData}`;
      }

      await axios.post(editDataUrl);
      props.isVisible(false);
    } catch (error) {
      console.error("Error editing event:", error);
    }
  };
  //Edited Data ends here

  const handleDelete = () => {
    props.setConfirm({
      isShow: true,
      eventCount: calendarEvent.event.eventcount,
    });
  };

  const handleSlideshow = () => {
    alert("work in progress");
  };
  const handleSaveBtn = () => {
    setIsEditing(false);
    setSaveBtn(true);
  };
  const handleBack = () => {
    setIsEditing(true);
  };
  const multipleDelete = (e) => {
    let value = e.target.getAttribute("id");
    let deleteURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_delete_calendar_events&moodlewsrestformat=json&events[0][eventid]=${id}&events[0][repeat]=${value}`;

    axios.get(deleteURL).then((response) => {
      props.isVisible(false);
      props.setConfirm({ isShow: false, eventCount: null });
    });
  };

  const dateTime = (dataTime) => {
    const date = new Date(dataTime * 1000);
    return date.toDateString();
  };

  const closePopup = () => {
    props.isVisible(false);
    props.setConfirm({ isShow: false, eventCount: null });
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      // color: theme.palette.tooltip.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.tooltip.backgroundColor,
      color: theme.palette.tooltip.main,
      border: "1px solid white",
    },
  }));
  //In case of edit I need to send repeatID as well
  return (
    <>
      {!props.confirm.isShow ? (
        <Box className="" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                <Typography variant="h6">{calendarEvent.event.name}</Typography>
                <BootstrapTooltip title="Close">
                  <Button
                    sx={{
                      backgroundColor: "secondaryColor.main",
                      color: "white",
                    }}
                    className="popupCloseButton"
                    onClick={closePopup}
                    color="popupbutton"
                    variant="contained"
                  >
                    {" "}
                    <CloseIcon />
                  </Button>
                </BootstrapTooltip>
              </DialogTitle>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <TextIcon
                      text={`Event ${curLanguage.name}`}
                      icon={<HiOutlineCalendar size={20} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <Typography>
                          <b>
                            {" "}
                            {editedData.editEventName
                              ? editedData.editEventName
                              : calendarEvent.event.name}
                          </b>
                        </Typography>
                      ) : (
                        <TextField
                          id="outlined"
                          // placeholder={calendarEvent.event.name}
                          name="editEventName"
                          value={editedData.editEventName}
                          onChange={handleContentChange}
                          type="text"
                          fullWidth
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <TextIcon
                      text={"Date"}
                      icon={<MdOutlineCalendarMonth size={20} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <Typography>
                          <b>
                            {FormatDateTime(calendarEvent.event.timesort)?.date}
                          </b>
                        </Typography>
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={editedData.editEventDate}
                            onChange={handleDateChange}
                            placeholder={dateTime(
                              calendarEvent.event?.timesort
                            )}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            disablePast
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <TextIcon
                      text={"Time"}
                      icon={<AiOutlineClockCircle size={20} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <Typography>
                          <b>
                            {FormatDateTime(calendarEvent.event?.timesort).time}
                          </b>
                        </Typography>
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={editedData.editEventDate}
                            onChange={handleTimeChange}
                            placeholder={dateTime(
                              calendarEvent.event?.timesort
                            )}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <TextIcon
                      text={curLanguage.description}
                      icon={<IoDocumentTextOutline size={20} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <Typography>
                          {" "}
                          <b>
                            {calendarEvent.event?.description
                              ? parseHTML(calendarEvent.event?.description)
                              : "Not Available"}
                          </b>
                        </Typography>
                      ) : (
                        <TextField
                          id="outlined"
                          name="editDescription"
                          value={editedData.editDescription}
                          onChange={handleContentChange}
                          type="text"
                          multiline
                          fullWidth
                        />
                      )}
                    </Box>
                  </Grid>

                  {userinfo[0]?.department.toLowerCase() === "student" ? (
                    ""
                  ) : (
                    <>
                      {calendarEvent.event.groupname && (
                        <Grid item md={4}>
                          <TextIcon
                            text={`Created For`}
                            icon={<AiOutlineUser size={20} />}
                          />
                          <Box className="modalContent">
                            {isEditing ? (
                              <Typography className="textTransform">
                                <b>
                                  {calendarEvent.event.groupname?.split(".")[1]}{" "}
                                </b>
                              </Typography>
                            ) : (
                              <FormControl fullWidth>
                                <Select
                                  labelid="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={editedData.editGroupid}
                                  name="editGroupid"
                                  label=""
                                  onChange={handleContentChange}
                                  sx={{ textTransform: "capitalize" }}
                                  fullWidth
                                >
                                  {Array.from(new Set(editedClass)).map(
                                    (value) => (
                                      <MenuItem
                                        key={value}
                                        value={value.group_id}
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {value?.classsection}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        </Grid>
                      )}
                    </>
                  )}
                  {calendarEvent.event.course && (
                    <>
                    <Grid item md={4}>
                      <TextIcon
                        text={`Subject`}
                        icon={<MdOutlineBook size={20} />}
                      />
                      <Box className="modalContent">
                        {isEditing ? (
                          <Typography>
                            <b>{calendarEvent.event.course?.fullname}</b>
                          </Typography>
                        ) : (
                          <FormControl fullWidth>
                            <Select
                              labelid="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={editedData.editCourseid}
                              name="editCourseid"
                              label=""
                              onChange={handleContentChange}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {filterSubjects(editedData.editGroupid).map(
                                (data) => (
                                  <MenuItem key={data.id} value={data.courseid}>
                                    {filterSubjectsName(data.courseid).map(
                                      (val) => (
                                        <> {val.fullname}</>
                                      )
                                    )}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                      <Grid item md={4}>
                      <TextIcon
                        text={`Created By`}
                        icon={<AiOutlineUser size={20} />}
                      />
                      <Box className="modalContent">
                        <Typography>
                          <b>{calendarEvent.event.username}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    </>
                  )}

                
                </Grid>
              </DialogContent>
              {calendarEvent.event.candelete ? (
                <DialogActions
                  sx={{
                    borderColor: "secondaryColorOutline.border",
                    backgroundColor: "cardColor.main",
                  }}
                >
                  {!isEditing && (
                    <PrimaryBgBtn onClick={handleBack}>
                      {curLanguage.back}
                    </PrimaryBgBtn>
                  )}
                  <PrimaryOutlineBtn
                    onClick={handleDelete}
                    sx={{
                      minWidth: {
                        xs: "105px !important",
                        sm: "120px !important",
                      },
                    }}
                  >
                    {curLanguage.delete}
                  </PrimaryOutlineBtn>
                  {isEditing && (
                    <PrimaryBgBtn
                      onClick={handleSaveBtn}
                      sx={{
                        minWidth: {
                          xs: "105px !important",
                          sm: "120px !important",
                        },
                      }}
                    >
                      {curLanguage.edit}
                    </PrimaryBgBtn>
                  )}
                  {!isEditing && (
                    <PrimaryBgBtn
                      onClick={() =>
                        handleSaveEditData(
                          calendarEvent.event.id,
                          calendarEvent.event.userid,
                          calendarEvent.event.repeatid,
                          calendarEvent.event.timestart,
                          calendarEvent.event.eventtype,
                          calendarEvent.event.course?.id,
                          calendarEvent.event.groupid,
                          calendarEvent.event.description,
                          calendarEvent.event.eventtype
                        )
                      }
                    >
                      {curLanguage.save}
                    </PrimaryBgBtn>
                  )}

                  {moduleNames.indexOf(calendarEvent.event.modulename) > -1 ? (
                    <Button
                      className="deleteEvent"
                      color="popupbutton"
                      variant="contained"
                      onClick={handleSlideshow}
                    >
                      START
                    </Button>
                  ) : (
                    ""
                  )}
                </DialogActions>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      {props.confirm.isShow ? (
        <Box className="eventWrapper" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                {calendarEvent.event.name}
                <Button
                  className="popupCloseButton"
                  onClick={closePopup}
                  variant="contained"
                  sx={{ minWidth: 10 }}
                >
                  <CloseIcon />{" "}
                </Button>
              </DialogTitle>
              <DialogContent>
                <Box className="confirm">{curLanguage.areYouSure}?</Box>
              </DialogContent>

              <DialogActions
                sx={{
                  borderColor: "secondaryColorOutline.border",
                  backgroundColor: "cardColor.main",
                }}
              >
                {props.confirm.eventCount > 1 ? (
                  <>
                    <PrimaryBgBtn id="0" onClick={multipleDelete}>
                      {curLanguage.delete}
                    </PrimaryBgBtn>

                    <PrimaryOutlineBtn id="1" onClick={multipleDelete}>
                      {curLanguage.deleteAllEvent}
                    </PrimaryOutlineBtn>
                  </>
                ) : (
                  <>
                    <PrimaryOutlineBtn id="1" onClick={closePopup}>
                      {curLanguage.cancel}
                    </PrimaryOutlineBtn>
                    <PrimaryBgBtn id="0" onClick={multipleDelete}>
                      {curLanguage.delete}
                    </PrimaryBgBtn>
                  </>
                )}
              </DialogActions>
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
export default GetCalendarEvent;
