import {
  Box,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DownloadImage } from "../../_Components/Common/UploadFile";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

const DownloadAllAttendance = ({ studentInfo, attendanceinfo, selectedMonthVal }) => {
  console.log(selectedMonthVal, "selectedMonthVal")
  const monthVal = selectedMonthVal
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const financialYear = localStorage.getItem("AY");
  const stdIDS = studentInfo.map((student) => student.id);
  console.log(monthVal, "selectedMonthVal")
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonthIndex = new Date().getMonth();
  const pastAndCurrentMonths = monthNames.slice(0, currentMonthIndex + 1);

  const onHandleChange = (event) => {
    const { value } = event.target;
    setSelectedMonth(value);
    console.log(value, "selectedMonthVal")
  };

  const attendanceDownload = async () => {
    try {
      const siteInfoUrl = `${process.env.REACT_APP_nodewsPrefix}/bulkdataexportCSV?type=attendance&class=${studentInfo[0].class}&section=${studentInfo[0].section}&financial_year=${financialYear}&student_id=${stdIDS}&day=&month=${selectedMonth}`;

      const siteInfoResponse = await axios.get(siteInfoUrl);
      const blob = new Blob([siteInfoResponse.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "attendance.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading attendance:", error);
    }
    setOpen(false);
    setSelectedMonth("")
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMonth("")
  };

  return (
    <>
      <Button onClick={handleOpen} sx={{ padding: "0", minWidth: "20px" }}>
        <DownloadImage mssg="Download All Student Attendance" />
      </Button>

      <CustomDialog isOpen={open} width={"sm"} onClose={handleClose}>
        <Box p={2}>
          <Typography variant="h6" textAlign={"center"}>
           Select a month to download the class attendance.
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="department-label">Month</InputLabel>
            <Select
              labelid="department-label"
              id="department"
              name="month"
              label="month"
              value={selectedMonth ? selectedMonth : monthVal}
              onChange={onHandleChange}
            >
              {pastAndCurrentMonths.map((month, index) => (
                <MenuItem key={month} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CenterButtonBoxArea sx={{ mt: 2 }}>
            <PrimaryBgBtn onClick={attendanceDownload} disabled={!monthVal}>
              Download Attendance
            </PrimaryBgBtn>
          </CenterButtonBoxArea>
        </Box>
      </CustomDialog>
    </>
  );
};

export default DownloadAllAttendance;
