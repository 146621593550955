import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function Assignments() {
  const [open, setOpen] = useState(false);
  const { permissions } = usePermissions();
  const [viewAssessment, setViewAssessment] = useState([]);
  const [deleteAssignment, setDeleteAssignment] = useState("");
  const courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const userInfoData = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userInfoData);
  const [isLoading, setIsLoading] = useState(false);
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const stdId = JSON.parse(localStorage.getItem("classInfo"))?.classid;

  const handleOpenDialog = (val) => {
    setOpen(true);
    setDeleteAssignment(val);
  };

  const columnsStudent = [
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.scoid}
            variant="contained"
            sx={{
              backgroundColor:
                cellValues.row.status === "Submited" ? "#0f7700" : "#2769aa",
              color: "secondaryColor.contrastText",
            }}
            onClick={() =>
              openLinkInTab(
                cellValues.row.scoid,
                stdId,
                cellValues.row.assignmentid
              )
            }
          >
            {console.log(cellValues, "cellValues")}
            <Typography>Go</Typography>
          </Button>
        );
      },
    },
    { field: "assignmentName", headerName: "Assignment Name", width: 250 },
    { field: "assignmentType", headerName: "Assignment Type", width: 150 },
    { field: "courseSubject", headerName: "Subject", width: 100 },
    { field: "assignedOn", headerName: "Assigned On", width: 180 },
    { field: "submitedOn", headerName: "Submited On", width: 180 },
    {
      field: "expectedcompletion",
      headerName: "Expected Completion",
      width: 180,
    },
    { field: "status", headerName: "Status", width: 150 },
    { field: "maxmarks", headerName: "Marks" },
    /* { field: "submissions", headerName: "Submissions" } */
  ];
  function filterUniqueAssignmentIds(data) {
    const uniqueAssignments = {};
    data.forEach((item) => {
      const assignmentid = item.assignmentuniqueid;
      if (!uniqueAssignments[assignmentid]) {
        uniqueAssignments[assignmentid] = item;
      }
    });

    return Object.values(uniqueAssignments);
  }

  let modifiedViewAssessment = [];

  if (viewAssessment.data) {
    modifiedViewAssessment = filterUniqueAssignmentIds(viewAssessment.data);
  }
  let columns;

  const columnsTeacher = [
    {
      field: "Status",
      headerName: "Action",
      hideable: false,
      width: 120,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              id={cellValues.row.scoid}
              state={cellValues.row.classid}
              variant="contained"
              sx={{
                backgroundColor:
                  cellValues.row.status === "submitted" ? "green" : "#2769aa",
                color: "secondaryColor.contrastText",
              }}
              onClick={() =>
                openLinkInTab(
                  cellValues.row.scoid,
                  cellValues.row.classid,
                  cellValues.row.assignmentid,
                  cellValues.row.assignSubmit
                )
              }
            >
              <Typography>Go</Typography>
            </Button>
            <IconButton
              onClick={() => handleOpenDialog(cellValues.row.assignmentid)}
              style={{
                background: "#fd4d4d",
                boxShadow: "2px 2px 2px #939292",
                marginLeft: "5px",
              }}
            >
              <RiDeleteBin6Line fontSize={"18px"} color="#fff" />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "bookName",
      headerName: "Book",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "assignmentName",
      headerName: "Name",
      width: 200,
      align: "left",
      headerAlign: "left",
    },

    { field: "assignmentType", headerName: "Type", width: 150 },
    { field: "courseSubject", headerName: "Subject", width: 150 },
    { field: "class", headerName: "Class", width: 100 },
    { field: "assignedOn", headerName: "Assigned On", width: 180 },
    {
      field: "maxmarks",
      headerName: "Marks",
      width: 150,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "assignSubmit",
      headerName: "Submit Count",
      width: 140,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "questioncount",
      headerName: "Question Count",
      width: 140,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
  ];

  if (userinfoObject[0].department.toLowerCase() === "pedagogy") {
    columns = columnsTeacher;
  } else {
    columns = columnsStudent;
  }

  let rows;

  const rowsTeacher =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? modifiedViewAssessment.map((values, indx) => ({
          id: indx,
          courseid: values.courseid,
          assignmentName: `Assignment ${values.assignmentuniqueid}`,
          bookName: `${values.scromname}`,
          assignmentType: values.type === "cw" ? "Classwork" : "Homework",
          courseSubject: values.coursename,
          questionCount: values.questioncount,
          assignedOn: values.assignedon,
          expectedcompletion: values.expectedcompletion,
          submitedOn: values.timecreated,
          class: values.classsection,
          assignSubmit: values.assignment_submit_count,
          status: values.submitstatus ? "Submited" : "Not Attempted",
          maxmarks: values.maxmarks,
          questioncount: values.questioncount,
          scoid: values.scoid,
          classid: values.classid,
          assignmentid: values.assignmentuniqueid,
        }))
      : [];

  const rowsStudent =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? viewAssessment.data.map((values, indx) => ({
          id: indx,
          courseid: values.courseid,
          assignmentName: `Assignment ${values.assignmentuniqueid}`,
          bookName: `${values.scromname}`,
          assignmentType: values.type === "cw" ? "Classwork" : "Homework",
          courseSubject: values.coursename,
          questionCount: values.questioncount,
          assignedOn: values.assignDate,
          expectedcompletion: values.expectedcompletion,
          submitedOn: values.timecreated,
          class: values.classsection,
          status: values.submitstatus ? "Submited" : "Not Attempted",
          maxmarks: values.maxmarks,
          questioncount: values.questioncount,
          scoid: values.scoid,
          assignmentid: values.assignmentid,
        }))
      : [];

  if (userinfoObject[0].department.toLowerCase() === "student") {
    rows = rowsStudent;
  } else {
    rows = rowsTeacher;
  }

  useEffect(() => {
    if (userinfoObject[0].department.toLowerCase() === "pedagogy") {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
      axios
        .post(
          v_assessmentURL,
          {
            userid: userinfoObject[0].id,
            schoolcode: userinfoObject[0].institution,
            // courseid: courseinfoObject[0].id,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
      axios
        .post(
          v_assessmentURL,
          {
            userid: userinfoObject[0].id,
            // courseid: courseinfoObject[0].id,
            classid: stdId,
            schoolcode: userinfoObject[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);
  const handleDeleteAssignment = () => {
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/deleteAssignmentData`,
        {
          assignment_unique_id: deleteAssignment,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then(() => {
        setViewAssessment((prevAssessments) => {
          if (prevAssessments?.data) {
            return {
              ...prevAssessments,
              data: prevAssessments.data.filter(
                (assignment) =>
                  assignment.assignmentuniqueid !== deleteAssignment
              ),
            };
          } else {
            console.error(
              "Invalid structure for prevAssessments:",
              prevAssessments
            );
            return prevAssessments;
          }
        });

        setOpen(false);
      })
      .catch((error) => {
        console.error("Error deleting assignment:", error);
      });
  };
  const setLink = (scoid, scormid, classid) => {
    let links;
    links = `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${scoid}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;
    return links;
  };

  const openLinkInTab = async (scoid, classid, assignmentID, submitCount) => {
    // var scoid = scoid;
    try {
      await axios
        .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${scoid}`)
        .then((response) => {
          window.open(setLink(scoid, response.data.id, classid), "");
        });

      permissions.classes?.write &&
        (await axios

          .post(
            `${process.env.REACT_APP_nodewsPrefix}/updateAssignedHWCWByTeacherCourse`,

            {
              userid: userinfoObject[0].id,
              assignment_id: assignmentID,
              submitcount: submitCount,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          }));

      permissions.courses?.write &&
        (await axios

          .post(
            `${process.env.REACT_APP_nodewsPrefix}/UpdateAssignedCountHWCWStudent`,

            {
              userid: userinfoObject[0].id,
              assignment_id: assignmentID,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          }));
    } catch {
      console.log("error");
    }
  };

  return (
    <MainCard cardTitle={"Assignments"}>
      <>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <>
            {viewAssessment.data && viewAssessment.data.length > 0 ? (
              <div style={{ height: 680, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  componentsProps={{
                    columnsPanel: { disableHideAllButton: true },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    // sorting: {
                    //   sortModel: [{ field: 'questioncount', sort: 'asc' }],
                    // },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <Box
                sx={{
                  color: "red",
                  padding: "10px 40px",
                  fontFamily: "Helvetica",
                }}
              >
                No Assingments found
              </Box>
            )}
          </>
        )}
      </>
      <CustomDialog isOpen={open} dialogTitle="Confirmation">
        <Box p={2}>
          <Typography textAlign={"center"}>
            Are you sure you want to delete this assignment?
            <br /> All attempts made by the students will be permanently
            deleted.
          </Typography>
          <CenterButtonBoxArea sx={{ mt: 2 }}>
            <PrimaryBgBtn onClick={handleDeleteAssignment}>Ok</PrimaryBgBtn>
            <PrimaryOutlineBtn onClick={() => setOpen(false)}>
              Cancel
            </PrimaryOutlineBtn>
          </CenterButtonBoxArea>
        </Box>
      </CustomDialog>
    </MainCard>
  );
}
export default Assignments;
