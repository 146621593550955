import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassSection } from "../../../redux/apiSlice/classSectionSlice";
import CustomDialog from "../../../_Components/Common/CustomDialog/CustomDialog";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import { montArr } from "../../../_Components/Common/MonthArray";

const FeeStructureForm = () => {
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [classData, setClassData] = useState([]);
  const [customAlert, setCustomAlert] = useState(false);
  const [customDialogData, setCustomDialogData] = useState({
    titleHead: "",
    message: "",
    contentData: "",
  });
  const [feeStructures, setFeeStructures] = useState([
    { feeTypeLabel: "", feeTypeValue: "" },
  ]);
  const [formData, setFormData] = useState({
    getMonth: "",
    selectedMonths: [],
    discount: "",
    discountType: "",
    classSec: "",
  });

  const dispatch = useDispatch();
  const { classSectionData, classSectionLoading } = useSelector(
    (data) => data.classSectionInfo
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTypeChange = (e, index) => {
    const { name, value } = e.target;
    setFeeStructures((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [name]: value } : item))
    );
  };

  const addFeetype = () => {
    setFeeStructures((prev) => [
      ...prev,
      { feeTypeLabel: "", feeTypeValue: "" },
    ]);
  };

  const removeFeetype = () => {
    setFeeStructures((prev) =>
      prev.length > 1 ? prev.slice(0, prev.length - 1) : prev
    );
  };

  const handleAlertClose = () => {
    setCustomAlert(false);
  };

  useEffect(() => {
    if (!classSectionLoading) {
      dispatch(
        fetchClassSection({
          userinfo,
          nToken,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!classSectionLoading && classSectionData?.length > 0) {
      setClassData(classSectionData);
    }
  }, [classSectionLoading, classSectionData]);

  const cleanFeeStructures = Array.isArray(feeStructures)
    ? feeStructures.map(({ feeTypeLabel, feeTypeValue }) => ({
        feeTypeLabel,
        feeTypeValue,
      }))
    : [];

  const feeTypePayload = cleanFeeStructures?.reduce(
    (acc, { feeTypeLabel, feeTypeValue }) => {
      acc[feeTypeLabel] = feeTypeValue;
      return acc;
    },
    {}
  );

  const feeTypePayloadVal = formData.selectedMonths.reduce((acc, month) => {
    acc[month] = { ...feeTypePayload };
    return acc;
  }, {});

  const handleFormData = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/classFeeStructureAdd`,
        {
          overwrite: 1,
          schoolcode: userinfo[0].institution,
          academic_year: financialYear,
          class_name: formData.classSec,
          added_by: userinfo[0].id,
          fee_type: feeTypePayloadVal,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const successMessage = response?.data?.message;
      setCustomDialogData({
        titleHead: response?.data?.error ? "Failed" : "Success",
        message: successMessage,
        contentData: response.data,
      });
      setCustomAlert(true);
      setFormData({
        getMonth: "",
        selectedMonths: [],
        discount: "",
        discountType: "",
        classSec: "",
      });
    } catch (error) {
      console.error("Error adding fee structure:", error);
    }
  };

  const isFormEmpty =
    !formData.getMonth && !formData.discount && !formData.classSec;

  return (
    <>
      <Box component={"form"} onSubmit={handleFormData}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="classSecLabel">Class</InputLabel>
                  <Select
                    labelId="classSecLabel"
                    id="classSec"
                    value={formData.classSec}
                    label="Class"
                    name="classSec"
                    onChange={handleChange}
                  >
                    {Array.from(
                      new Set(
                        classData.map((obj) => obj.class).sort((a, b) => a - b)
                      )
                    ).map((data) => (
                      <MenuItem key={data} value={data}>
                        Class {data}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="Months-label">Months</InputLabel>
                  <Select
                    labelId="Months-label"
                    id="Months"
                    multiple
                    value={formData.selectedMonths || []}
                    onChange={(event) => {
                      const { value } = event.target;
                      let updatedMonths = value;

                      setFormData((prev) => ({
                        ...prev,
                        selectedMonths: updatedMonths,
                      }));
                    }}
                    name="selectedMonths"
                    label="Months"
                    renderValue={(selected) =>
                      selected.map((index) => montArr[index - 1]).join(", ")
                    }
                  >
                    {montArr.map((data, index) => (
                      <MenuItem key={index} value={index + 1}>
                        <Checkbox
                          checked={formData.selectedMonths?.includes(index + 1)}
                        />
                        <ListItemText primary={data} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {feeStructures?.map((feeStructure, index) => (
                  <Grid container spacing={2} key={index} alignItems={"center"}>
                    <Grid item xs={4} mb={2}>
                      <FormControl fullWidth>
                        <TextField
                          id={`feeTypeLabel-${index}`}
                          label="Type"
                          variant="outlined"
                          name="feeTypeLabel"
                          type="text"
                          value={feeStructure.feeTypeLabel}
                          onChange={(e) => handleTypeChange(e, index)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} mb={2}>
                      <FormControl fullWidth>
                        <TextField
                          id={`feeTypeValue-${index}`}
                          label="Amount"
                          variant="outlined"
                          name="feeTypeValue"
                          type="number"
                          value={feeStructure.feeTypeValue}
                          onChange={(e) => handleTypeChange(e, index)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Box display="flex" justifyContent="flex-start" gap={2}>
                        {index === feeStructures.length - 1 && (
                          <IconButton onClick={addFeetype}>
                            <IoIosAddCircleOutline />
                          </IconButton>
                        )}

                        {feeStructures.length > 1 && (
                          <IconButton onClick={removeFeetype}>
                            <IoIosRemoveCircleOutline />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <CenterButtonBoxArea mt={2}>
              <PrimaryBgBtn type="submit" disabled={isFormEmpty}>
                Submit
              </PrimaryBgBtn>
            </CenterButtonBoxArea>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography>dfdf</Typography>
          </Grid>
        </Grid>
      </Box>

      <CustomDialog
        isOpen={customAlert}
        cardTitle={customDialogData.titleHead}
        onClose={handleAlertClose}
        actionBtn={
          <>
            <PrimaryOutlineBtn onClick={handleAlertClose}>
              Cancel
            </PrimaryOutlineBtn>
            <PrimaryBgBtn onClick={handleFormData}>Save</PrimaryBgBtn>
          </>
        }
      >
        <Box p={2} textAlign={"center"}>
          {customDialogData.message}
          {customDialogData?.contentData && (
            <>
              {customDialogData?.contentData?.fee_typeData && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      <TableCell colSpan={2}>Fee Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(
                      customDialogData.contentData.fee_typeData
                    ).map(([monthNumber, feeDetails]) => (
                      <TableRow key={monthNumber}>
                        <TableCell>
                          {montArr[parseInt(monthNumber, 10) - 1]}
                        </TableCell>
                        <TableCell>Tution: {feeDetails.Tution} Rs</TableCell>
                        <TableCell>
                          Development: {feeDetails.Development} Rs
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </Box>
      </CustomDialog>
    </>
  );
};

export default FeeStructureForm;
