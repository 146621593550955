import React, { useEffect, useState } from 'react';
import {
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import axios from 'axios';
import MainCard from "../../../_Components/Common/Cards/MainCard";
import PrimaryCard from '../../../_Components/Common/Cards/PrimaryCard';
import StudentList from './StudentList';
import { PrimaryBgBtn } from '../../../_Components/Common/Buttons/CustomBtns';
import Loader from '../../../_Components/Utils/loader/Loader';
const AdmReport = () => {
    const [academicYear, setAcademicYear] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const nToken = localStorage.getItem("nToken");
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = localStorage.getItem("token");
    const [formValues, setFormValues] = useState([
        {
            stdClass: "",
            stdsection: "",
        },
    ]);
    const [Data, setData] = useState()
    const [stdClassInfo, setStdClassInfo] = useState([]);
    const [reportType, setReportType] = useState('');
    const [count, setCount] = useState('');
    const [open, setOpen] = useState(false);
    const [messageReponse, setMessageReponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };
    const handleYearChange = (event) => setSelectedYear(event.target.value);
    const handleReportTypeChange = (event) => {
        setReportType(event.target.value);
        setCount('');
    };
    const handleCountChange = (event) => setCount(event.target.value);

    const handleSubmit = async () => {
        const data = {
            selectedYear,
            formValues,
            reportType,
            count: parseInt(count, 10),
        };
        console.log(data);

        const newData = {
            academic_year: selectedYear,
            class: formValues[0].stdClass,
            section: formValues[0].stdsection,
            reportType,
            count: parseInt(count, 10),
            schoolcode: userinfo[0]?.institution
        }
        console.log(newData);


        try {
            const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getMarksReports?schoolcode=${userinfo[0]?.institution}&class=${newData.class}&section=${newData.section}&reportType=${reportType}&count=${newData.count}&academic_year=${newData.academic_year}`;
            const response = await axios.get(baseURL, {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                },
            });

            console.log('Response:', response.data.studentData);
            setData(response.data.studentData)

            setMessageReponse(response.data.message)
            if (response.data.error) {
                setOpen(true);
            }
            if (response.data.success) {
                setOpen(true);
                setIsLoader(false)
            }
            // setOpen(true);
            if (response.data.studentData) {
                const insertData = {
                    added_by: "1",
                    class: formValues[0].stdClass,
                    section: formValues[0].stdsection,
                    school_code: userinfo[0]?.institution,
                    studentData: response.data.studentData,
                    academic_year: selectedYear

                }

                const insertDataURL = `${process.env.REACT_APP_nodewsPrefix}/addMarksReports`
                axios.post(insertDataURL, insertData, {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    }
                }).then(response => {
                    console.log(response.data, "raj111");
                    setIsLoader(false)
                }).catch(err => {
                    console.log(err);
                });


            }
            // alert('Data submitted successfully!');
        } catch (error) {
            console.error('Error submitting data:', error);
            // alert('Failed to submit data!');
        }
    };

    const numberOptions = reportType === 'Semester' ? [1, 2, 3, 4] : [1, 2, 3, 4];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
                const response = await axios.get(baseURL, {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    },
                });

                const data = response.data.class_section;
                console.log(data);


                setClasses([...new Set(data.map((item) => item.class))]); // Unique classes
                setSections([...new Set(data.map((item) => item.section))]); // Unique sections
            } catch (error) {
                console.error("Error fetching class and section data:", error);
            }
            try {
                const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getAcademicYear?schoolcode=${userinfo[0]?.institution}`
                const response = await axios.get(baseURL, {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    },
                });

                const data = response.data.academic_year_data;
                setAcademicYear(data)
            } catch (error) {
                console.error("Error fetching academicYear:", error);
            }
        };

        fetchData();
    }, []);
    const handleInputData = (index, event) => {
        const { name, value } = event.target;
        const newFormValues = [...formValues];
        newFormValues[index][name] = value;

        if (name === "stdsection" && value === sections) {
            newFormValues[index][name] = sections;
        } else {
            newFormValues[index][name] = value;
        }

        setFormValues(newFormValues);
        if (name === "stdClass" || name === "stdsection") {
            const selectedClass =
                name === "stdClass" ? value : newFormValues[index].stdClass;
            const selectedSection =
                name === "stdsection" ? value : newFormValues[index].stdsection;

            if (selectedClass && selectedSection) {
                const selectedClassInfo = stdClassInfo.find(
                    (item) =>
                        item.class === parseInt(selectedClass) &&
                        item.section === selectedSection
                );

            } else if (selectedClass) {
                const selectedClassInfo = stdClassInfo.find(
                    (item) => item.class === parseInt(selectedClass)
                );

            }
        }
    };

    return (
        <>
            <MainCard cardTitle="Report Card" customClass="">
                <Box>
                    {/* <Typography variant="h6" gutterBottom>
          Report Card Submission
        </Typography> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row', // Arrange horizontally
                            gap: 10, // Add space between items
                            alignItems: 'center', // Align vertically in the center
                            flexWrap: 'wrap', // Allow wrapping if space is small
                        }}
                    >
                        {/* Academic Year */}
                        <FormControl size="small" sx={{ minWidth: 180 }}>
                            <InputLabel>Academic Year</InputLabel>
                            <Select value={selectedYear} onChange={handleYearChange} label="Academic Year">
                                {Array.isArray(academicYear) &&
                                    academicYear.map((year) => (
                                        <MenuItem key={year.academic_year_id} value={year.academic_year_id}>
                                            {year.academic_year}
                                        </MenuItem>
                                    ))}
                                {/* <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem> */}
                            </Select>
                        </FormControl>
                        {formValues.map((formValue, index) => (
                            <>
                                <FormControl size="small" sx={{ minWidth: 100 }}>
                                    <InputLabel id="stdClass-label">Class</InputLabel>
                                    <Select
                                        labelId="stdClass-label"
                                        id="stdClass"
                                        name="stdClass"
                                        value={formValue.stdClass}
                                        onChange={(event) => handleInputData(index, event)}
                                        label="Class"
                                    >
                                        {classes.map((cls) => (
                                            <MenuItem key={cls} value={cls}>
                                                {cls}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl size="small" sx={{ minWidth: 100 }}>
                                    <InputLabel id="stdsection-label">Section</InputLabel>
                                    <Select
                                        labelId="stdsection-label"
                                        id="stdsection"
                                        name="stdsection"
                                        value={
                                            formValue.stdsection === "All"
                                                ? "All Section"
                                                : formValue.stdsection
                                        }
                                        onChange={(event) => handleInputData(index, event)}
                                        label="Section"
                                    >
                                        <MenuItem value={sections}>All Sections</MenuItem>
                                        {sections.map((section) => (
                                            <MenuItem key={section} value={section}>
                                                {section}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        ))}


                        {/* Report Type */}
                        <FormControl size="small" sx={{ minWidth: 150 }}>
                            <InputLabel>Report Type</InputLabel>
                            <Select value={reportType} onChange={handleReportTypeChange} label="Report Type">
                                <MenuItem value="Semester">Semester</MenuItem>
                                <MenuItem value="Term">Term</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Number of Semesters/Terms */}
                        {reportType && (
                            <FormControl size="small" sx={{ minWidth: 150 }}>
                                <InputLabel>No. of {reportType}s</InputLabel>
                                <Select value={count} onChange={handleCountChange} label={`Number of ${reportType}s`}>
                                    {numberOptions.map((num) => (
                                        <MenuItem key={num} value={num}>
                                            {num}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {/* Submit Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!academicYear || !reportType || !count}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>




                {isLoader ? <Loader /> :
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Report Type is Already Selected"}
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {messageReponse}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Disagree</Button> */}
                            <PrimaryBgBtn onClick={handleClose} autoFocus>
                                OK                        </PrimaryBgBtn>
                        </DialogActions>
                    </Dialog>}

            </MainCard >
            {/* <MainCard>
                {Data && Data ? <StudentList studentData={Data} class_section={formValues} academic_year={selectedYear} /> : ''}
            </MainCard> */}
        </>
    );

};

export default AdmReport;
