import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchClassSection = createAsyncThunk(
  "classSection/fetchClassSection",
  async ({ userinfo, nToken }) => {
    try {
      const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      const data = response.data.class_section;
      return data;
    } catch (error) {
      console.error("Error fetching class and section data:", error);
    }
  }
);

const classSectionSlice = createSlice({
  name: "classSection",
  initialState: {
    classSectionData: [],
    classSectionLoading: false,
    classSectionError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassSection.pending, (state) => {
        state.classSectionLoading = true;
        state.classSectionError = null;
      })
      .addCase(fetchClassSection.fulfilled, (state, action) => {
        state.classSectionData = action.payload;
        state.classSectionLoading = false;
        state.classSectionError = null;
      })
      .addCase(fetchClassSection.rejected, (state, action) => {
        state.classSectionLoading = false;
        state.classSectionError = action.error.message;
      });
  },
});

export default classSectionSlice.reducer
