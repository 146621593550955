import { Box, Typography } from "@mui/material";
import axios from "axios";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";

const TeacherSyllabus = ({ userInfo }) => {
  const [syllabusData, setSyllabusData] = useState([]);
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/viewSyllabusCourseSection`,
        {
          userid: userInfo[0].id,
          financial_year: financialYear,
          schoolcode: userInfo[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((res) => {
        setSyllabusData(res.data.checked_data);
      })
      .catch((err) => console.error(err));
  }, []);

  const teacherClass = [
    ...new Set(syllabusData?.map((data) => data.class_sectionID)),
  ];
  const teacherClassName = [
    ...new Set(syllabusData?.map((data) => data.class_name)),
  ];

  const courseNames = [
    ...new Set(syllabusData?.map((data) => data.CourseName)),
  ];

  const seriesData = courseNames.map((courseName) => {
    const courseData = syllabusData.filter(
      (data) => data.CourseName === courseName
    );
    const data = teacherClass.map((class_sectionID) => {
      const value =
        courseData.find((data) => data.class_sectionID === class_sectionID)
          ?.count || 0;
      return {
        value: isNaN(value) ? "-" : value,
        name: courseName,
        class_sectionID,
      };
    });
    return { name: courseName, type: "bar", data, barGap: 0, barWidth: "40px" };
  });

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: 50,
      right: 50,
      top: 50,
      bottom: 50,
    },
    xAxis: {
      type: "category",
      name: "Classes",
      nameLocation: "middle",
      nameGap: 30,
      data: teacherClassName,
      axisLabel: {
        fontSize: 16,
        fontWeight: "bold",
        color: "#000000",
      },
    },
    yAxis: {
      type: "value",
      name: "Number of Chapters",
      nameLocation: "middle",
      nameGap: 25,
    },
    series: seriesData,

    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        magicType: { show: false, type: ["bar", "line"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    legend: {
      show: true,
      data: courseNames,
    },
  };

  return (
    <>
      {syllabusData?.length > 0 ? (
        <ReactEcharts option={option} />
      ) : (
        <Box display={"flex"} justifyContent="center">
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/Datanotfound.gif`}
            alt="No Progress"
          />
        </Box>
      )}
    </>
  );
};

export default TeacherSyllabus;
