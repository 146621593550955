import { Link, useLocation, useNavigate } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import "./AssetsBox.scss";
import "./CourseContent.scss";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import MarkChapter from "./MarkChapter";
import Scrollbar from "react-scrollbars-custom";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import Loader from "../../_Components/Utils/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseCompletion } from "../../redux/apiSlice/courseCompletionSlice";
import { fetchCourseContentInfo } from "../../redux/apiSlice/courseContentSlice";
import { fetchActivityCompletion } from "../../redux/apiSlice/activityCompletionSlice";
import { IoIosLock } from "react-icons/io";

const CourseContent = () => {
  const navigate = useNavigate();
  const [courseInfo, setCourseInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [completedChapter, SetCompletedChapter] = useState([]);
  const [quizStatus, setQuizStatus] = useState();
  const [quizInfo, setQuizInfo] = useState();
  const [open, setOpen] = useState(false);
  const [isAttempted, setIsAttempted] = useState(false);
  const [dialogData, setDialogData] = useState({ title: "", desc: "" });
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingModuleId, setDeletingModuleId] = useState(null);
  const [message, setMessage] = useState({
    textMssg: "",
    imgName: "",
  });
  const [customAlert, setCustomAlert] = useState();
  const [moduleSectionName, setModuleSectionName] = useState("Test Generator");
  const [incompleteAssignment, setIncompleteAssignment] = useState([]);
  const location = useLocation();
  const { permissions } = usePermissions();
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const courseInfoObject = JSON.parse(localStorage.getItem("courseDetails"));
  const stdClassInfo = JSON.parse(localStorage.getItem("classInfo"));
  const searchParams = new URLSearchParams(location.search);

  const courseId = searchParams.get("id");

  const financialYear = localStorage.getItem("AY");
  const department = userinfoObject[0]?.department.toLowerCase();

  const fullname = searchParams.get("fullname");
  const stdclass = searchParams.get("stdclass");
  const section = searchParams.get("section");
  const classid = searchParams.get("classid");
  const studentName = userinfoObject[0]?.fullname || "Student";
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const courseName = fullname;

  const dispatch = useDispatch();
  const { courseCompletion, courseCompletionLoading } = useSelector(
    (data) => data.courseCompletion
  );
  const { courseContentInfo, courseContentInfoLoading } = useSelector(
    (data) => data.courseContentInfo
  );
  const { activityCompletiondata, activityCompletionloading } = useSelector(
    (data) => data.activityCompletion
  );

  useEffect(() => {
    if (!courseCompletionLoading) {
      dispatch(
        fetchCourseCompletion({
          courseId,
          classid,
          financialYear,
          userinfoObject,
          nToken,
        })
      );
    }
    if (!courseContentInfoLoading && courseId && token) {
      dispatch(
        fetchCourseContentInfo({
          courseId,
          token,
        })
      );
    }
    if (!activityCompletionloading) {
      dispatch(
        fetchActivityCompletion({
          courseId,
          userid: userinfoObject[0]?.id,
          department: userinfoObject[0]?.department,
          nToken,
          institution: userinfoObject[0]?.institution,
          academic_year: financialYear,
        })
      );
    }
  }, [dispatch]);

  const openNewAssessmentForm = (section) => {
    navigate(`/createquiz?courseid=${courseId}&section=${section}`);
  };

  function processObject(obj) {
    const newObj = { ...obj };
    if (newObj.modules && newObj.modules.length > 1) {
      newObj.modules = [
        ...newObj.modules,
        {
          name: "Add Assessment",
          url: "add",
          section: newObj.section,
        },
      ];
    }
    if (newObj.child && newObj.child.length > 0) {
      newObj.child = newObj.child.map((childObj) => processObject(childObj));
    }
    return newObj;
  }

  const addAssessment = (data) => {
    if (userinfoObject[0].department.toLowerCase() !== "student") {
      return data.map((obj) => processObject(obj));
    } else {
      return data;
    }
  };
  useEffect(() => {
    if (!courseCompletionLoading) {
      SetCompletedChapter(courseCompletion?.data || []);
    }
    if (!courseContentInfoLoading && courseContentInfo?.length > 0) {
      setCourseInfo(addAssessment(courseContentInfo));
      setLoading(false);
    }
    if (!activityCompletionloading) {
      setIncompleteAssignment(activityCompletiondata?.incompleteAssignment);
    }
  }, [
    courseCompletionLoading,
    courseContentInfoLoading,
    courseContentInfo,
    activityCompletionloading,
  ]);

  const editAssessments = (instanceID) => {
    console.log("editAssessments", instanceID);
    navigate(
      `/editquiz?editing=true&moduleID=${instanceID}&courseid=${courseId}`
    );
  };

  const deleteAssessments = (moduleID) => {
    setOpen(false);
    setIsDeleted(false);
    setDeletingModuleId(moduleID);
    setIsLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/quizDelete`,
        {
          url: `${process.env.REACT_APP_wsPrefix}/`,
          token: token,
          course_module_id: parseInt(moduleID),
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setDeletingModuleId(null);
        setIsDeleted(true);
        dispatch(
          fetchCourseContentInfo({
            courseId,
            token,
          })
        );
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        setDeletingModuleId(null);
        setIsDeleted("error");
        setTimeout(() => {
          setIsDeleted(false);
        }, 5000);
      });
  };

  const parts = courseInfoObject?.[0]?.shortname?.split("_") || [];
  const getcourseName = parts.length > 0 ? parts[0] : "";



  function findThemeByModuleId(courseInfo, moduleId) {
    for (const theme of courseInfo) {
        for (const child of theme.child) {
            if (child.modules.some(module => module.id === moduleId)) {
                return theme.name; // Return parent theme name
            }
        }
    }
    return null; // Return null if not found
}

  const handleOpenActivity = (moduleData, moduleInfo) => {
    console.log(courseInfo, "All data");
    const themeName = findThemeByModuleId(courseInfo, moduleData.id);
    const createLink = (scormid) => {
      console.log(moduleData, moduleInfo, themeName, "moduleData");

      const openActivityUrl =
        moduleData.name.toLowerCase() === "lesson plan"
          ? `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?userid=${userinfoObject[0]?.id}&stdclass=${stdclass}&coursename=${getcourseName}&semester=Subjects&subject=${fullname}&theme=${themeName}&lesson=${moduleInfo.name}&url=${process.env.REACT_APP_nodewsPrefix}&a=${moduleData.instance}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`
          : `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${moduleData.instance}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;

      return openActivityUrl;
    };

    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${moduleData.instance}`
      )
      .then((response) => {
        window.open(createLink(response.data?.id), "");
      });

    const recentUrl =
      moduleData.modname.toLowerCase() === "quiz"
        ? `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_view_quiz&moodlewsrestformat=json&quizid=${moduleData.instance}`
        : `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&scormid=${moduleData.instance}&wsfunction=mod_scorm_view_scorm`;

    return axios.get(recentUrl);
  };

  const viewAttemptsHandler = async (modValueID, title) => {
    try {
      const studentListURL = `${
        process.env.REACT_APP_wsPrefix
      }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${courseId}&schoolcode=${Number(
        userinfoObject[0]?.institution
      )}&class=${stdclass}&section=${section}`;

      const response = await axios.get(studentListURL);
      const studentsArray = Object.values(response.data);
      const studentsList = studentsArray.filter(
        (user) => user.department?.toLowerCase() === "student"
      );

      navigate("/GetCourseContent/modAccessInformation/quizstudentlist", {
        state: {
          list: studentsList,
          title: title,
          quizID: modValueID,
        },
      });
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  function convertToMinutes(timeLimit) {
    let totalMinutes = 0;

    const hourMatch = timeLimit.match(/(\d+)\s*hours?/i);
    const minuteMatch = timeLimit.match(/(\d+)\s*mins?/i);

    if (hourMatch) {
      totalMinutes += parseInt(hourMatch[1], 10) * 60;
    }

    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1], 10);
    }

    return totalMinutes;
  }
  const handleAlertClose = () => {
    setCustomAlert(false);
  };

  const startAttemptHandler = async (moduleData) => {
    console.log(moduleData, "startAttempt");

    const getQuizStatusURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/quiz/quizattempt.php?wsfunction=getquiz_attemptdata&wstoken=${token}`;
    const quizInfoURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_quiz_access_information&moodlewsrestformat=json&quizid=${moduleData.instance}`;
    const attemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_start_attempt&moodlewsrestformat=json&quizid=${moduleData.instance}`;

    const parameters = {
      quiz_id: moduleData.instance,
      user_id: userinfoObject[0]?.id,
    };

    try {
      const quizStatusResponse = await axios.post(
        getQuizStatusURL,
        JSON.stringify(parameters)
      );
      setQuizStatus(quizStatusResponse.data);
      const quizInfoResponse = await axios.get(quizInfoURL);
      setQuizInfo(quizInfoResponse.data);

      let quizProgress = null;
      if (quizStatusResponse.data) {
        const quizStatusData = quizStatusResponse.data;
        if (
          quizStatusData.every((arr) => Array.isArray(arr) && arr.length === 0)
        ) {
          quizProgress = 0;
        } else if (quizStatusData[1][0] === "inprogress") {
          console.log("Inprogress");
          quizProgress = 1;
        } else if (quizStatusData[1][0] === "finished") {
          console.log("finished");
          quizProgress = 2;
        }
      }
      const totalTime = convertToMinutes(quizInfoResponse.data?.accessrules[1]);
      if (quizProgress === 0) {
        const attemptResponse = await axios.get(attemptURL);
        console.log(attemptResponse.data, "attemptResponse");
        if (!attemptResponse?.data?.attempt?.id) {
          if (attemptResponse.data?.message === "No questions found") {
            setMessage({
              textMssg: "No Question added for this assessment.",
              imgName: "overtime",
            });
          } else {
            setCustomAlert(true);
            setMessage({
              textMssg: "Date is passed for this assignment",
              imgName: "overtime",
            });
          }
        } else {
          navigate(
            `/AssessmentAttempt?id=${attemptResponse.data.attempt.id}&quizId=${moduleData.instance}&totalTime=${totalTime}`
          );
        }
      } else if (quizProgress === 1) {
        navigate(
          `/AssessmentAttempt?id=${quizStatusResponse.data[0][0]}&quizId=${moduleData.instance}&totalTime=${totalTime}`
        );
      } else if (quizProgress === 2) {
        setCustomAlert(true);
        setMessage({
          textMssg: "You have already completed this assignment attempt.",
          imgName: "completetask",
        });
        const recentUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_view_quiz&moodlewsrestformat=json&quizid=${moduleData.instance}`;
        return axios.get(recentUrl);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const imageName = (value) => value.replace(/[\s:,\d-_]/g, "").toLowerCase();
  const assignmentInfo =
    userinfoObject[0].department.toLowerCase() === "student" &&
    incompleteAssignment?.length > 0;

  const activeAssignments = [
    "worksheet",
    "skill it",
    "main coursebook",
    "workbook",
  ];

  const quizScorm = (moduleType) => moduleType.toLowerCase() === "quiz";

  const isActiveAssignment = (moduleName) =>
    activeAssignments.some((assignment) =>
      moduleName.toLowerCase().includes(assignment.toLowerCase())
    );
  // Inder continue...
  const renderModules = (modules, moduleData) => {
    return modules.map((module) => {
      const isCurrentModuleLoading =
        isLoading && deletingModuleId === module.id;

      return (
        <>
          {isCurrentModuleLoading ? (
            <Box className="assetsBoxes">
              <Loader />
            </Box>
          ) : (
            <Box
              className={`${
                assignmentInfo &&
                !isActiveAssignment(module.name) &&
                !quizScorm(module.modname)
                  ? "assetsBoxes assetContent"
                  : "assetsBoxes"
              }`}
              key={module.id}
              onClick={() => {
                if (
                  assignmentInfo &&
                  !isActiveAssignment(module.name) &&
                  !quizScorm(module.modname)
                ) {
                  // Prevent further action
                  return;
                }
                if (module.modname === "quiz") {
                  if (
                    userinfoObject[0].department.toLowerCase() === "student"
                  ) {
                    startAttemptHandler(module);
                  } else {
                    viewAttemptsHandler(module.instance, module.name);
                  }
                } else if (module.name === "Add Assessment") {
                  openNewAssessmentForm(module.section);
                } else {
                  handleOpenActivity(module, moduleData);
                }
              }}
            >
              {assignmentInfo &&
                !isActiveAssignment(module.name) &&
                !quizScorm(module.modname) && (
                  <Box className="lockedBg">
                    <IoIosLock size={30} />
                  </Box>
                )}
              <Box className="titleFace" sx={{ color: "textsColor.secColor" }}>
                {module.name}
              </Box>
              <Box className={`imgBox`}>
                <img
                  src={`${
                    module.modname === "quiz"
                      ? `${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/assessment.png`
                      : `${
                          process.env.REACT_APP_wsPrefix
                        }/lms-assets/icons/courseIcons/${imageName(
                          module.name
                        )}.png`
                  }`}
                  className="assetOverlayImg"
                  alt={module.name}
                />
              </Box>
              <Box
                className={`overlayBox ${
                  module.modname === "quiz" && "quizOverlay"
                }`}
                style={{
                  pointerEvents:
                    assignmentInfo && !isActiveAssignment(module.name)
                      ? "none"
                      : "auto",
                }}
              >
                {module.modname === "quiz" &&
                  (department === "pedagogy" || department === "admin") && (
                    <>
                      <Box
                        className="delete"
                        onClick={(e) => {
                          setDeletingModuleId(module.id);
                          ifQuizAttempted(
                            e,
                            module.instance,
                            module.id,
                            "delete"
                          );
                        }}
                      >
                        <Tooltip title="Delete">
                          <DeleteForeverIcon />
                        </Tooltip>
                      </Box>
                      <Box
                        className="edit"
                        onClick={(e) => {
                          ifQuizAttempted(
                            e,
                            module.instance,
                            module.id,
                            "edit"
                          );
                        }}
                      >
                        <Tooltip title="Edit">
                          <EditIcon />
                        </Tooltip>
                      </Box>
                    </>
                  )}
                <Box className="topIcon">{module.name}</Box>
              </Box>
            </Box>
          )}
        </>
      );
    });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
          {
            courseid: courseId,
            classid: classid,
            teacherid: userinfoObject[0].id,
            financial_year: financialYear,
            schoolcode: userinfoObject[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        SetCompletedChapter(response?.data.data || []);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const completedStatusMap = useMemo(() => {
    const map = {};
    completedChapter.forEach((chapter) => {
      map[chapter.course_sectionid] = chapter.status === 1;
    });
    return map;
  }, [completedChapter]);

  const renderAccordion = (data) => {
    if (
      data.name === moduleSectionName &&
      userinfoObject[0].department.toLowerCase() === "student"
    ) {
      return null;
    }

    return (
      <>
        <Accordion key={data.id} className="customAccordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${data.id}-content`}
            id={`panel-${data.id}-header`}
            className="accordionSumm"
          >
            {data.name}
            {/* {permissions.markChapter?.write && permissions.markChapter?.read && (
              <>
                {data.child?.length === 0 && data.summary.includes("Chapter") && (
                  <MarkChapter
                    courseID={courseId}
                    valChild={data}
                    classid={classid}
                    userinfoObject={userinfoObject}
                    completedChapter={completedStatusMap[data.id]}
                  />
                )}
              </>
            )} */}
          </AccordionSummary>
          <AccordionDetails
            className={`accordionDetail ${
              data.child?.length > 0 ? "insideAccordion" : "mainAccordion"
            }`}
          >
            <Box className="activityContent">
              {data.child?.length > 0
                ? data.child.map((child) =>
                    renderAccordion(child, completedStatusMap)
                  )
                : renderModules(data.modules, data)}
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ifQuizAttempted = (e, instanceID, moduleID, action) => {
    e.stopPropagation();
    let ifAttemptedURL = `${process.env.REACT_APP_nodewsPrefix}/quizAttempt?quizID=${instanceID}`;

    axios
      .get(ifAttemptedURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        handleClickOpen();

        if (response.data.quizCount > 0) {
          // ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED
          setIsAttempted(true);

          if (action === "delete") {
            // if delete button is clicked
            console.log("ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED");
            setDialogData({
              title: "Delete",
              desc: "Assessment attempted by student it can not be deleted",
            });
          } else {
            console.log(
              "edit clicked ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED"
            );
            // if edit button is clicked
            setDialogData({
              title: "Edit",
              desc: "Assessment attempted by student it can not be edited",
            });
          }
        } else {
          // ASSESSMENT NOT HAVE ATTEMPTS CAN BE EDIT OR DELETED
          console.log("not attempted", action);
          setIsAttempted(false);

          if (action === "delete") {
            // if delete button is clicked
            console.log("Are you sure you want to delete this assessment ?");
            setDialogData({
              title: "Delete",
              desc: "Are you sure you want to delete this assessment ? ",
              instID: instanceID,
              moduleID: moduleID,
            });
          } else {
            handleClose();
            editAssessments(instanceID);
          }
        }
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        s
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogData.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isAttempted ? (
            <>
              <Button onClick={handleClose}>OK</Button>
            </>
          ) : (
            <>
              <Button onClick={() => deleteAssessments(dialogData.moduleID)}>
                Yes
              </Button>
              <Button onClick={handleClose} autoFocus>
                No
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <MainCard
        cardTitle={`${courseName} ${stdclass ? `${stdclass}${section}` : ""}`}
        additionalData={
          <>
            {userinfoObject[0]?.department?.toLowerCase() === "pedagogy" && (
              <Link
                className="btn linkBtn primaryBtn"
                to={`/GetCourseContent/allStudentsReport?courseid=${courseId}&coursename=${courseName}&stdclass=${stdclass}&section=${section}`}
              >
                Progress Report
              </Link>
            )}
            {userinfoObject[0]?.department?.toLowerCase() === "student" && (
              <Link
                className="btn linkBtn primaryBtn"
                to={`/GetCourseContent/StudentReport?id=${stdClassInfo?.id}&studentName=${studentName}&courseid=${courseId}&coursename=${courseName}&stdclass=${stdClassInfo?.class}&section=${stdClassInfo?.section}`}
              >
                View Progress
              </Link>
            )}
          </>
        }
      >
        <Scrollbar
          style={{ width: "100%", height: `calc(72vh - 20px)` }}
          className="customCardItem"
        >
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              {error || !courseInfo ? (
                <Typography color="error" align="center">
                  Failed to load course content.
                </Typography>
              ) : (
                <>
                  {courseInfo.map(
                    (data) => data.visible && renderAccordion(data)
                  )}
                </>
              )}
            </>
          )}
        </Scrollbar>
        {customAlert && (
          <CustomDialog isOpen={customAlert} onClose={handleAlertClose}>
            <DialogContent sx={{ textAlign: "center" }}>
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/${message.imgName}.png`}
                alt="completetask"
                loading="lazy"
                width={"150px"}
              />
              <Typography> {message.textMssg}</Typography>

              <PrimaryBgBtn
                onClick={handleAlertClose}
                style={{ marginTop: "20px" }}
              >
                Close
              </PrimaryBgBtn>
            </DialogContent>
          </CustomDialog>
        )}
      </MainCard>
    </>
  );
};

export default CourseContent;
