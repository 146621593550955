import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import {
    CenterButtonBoxArea,
    PrimaryBgBtn,
  } from "../../../_Components/Common/Buttons/CustomBtns";
  import { useEffect, useState } from "react";
  import axios from "axios";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchClassSection } from "../../../redux/apiSlice/classSectionSlice";
  import CustomDialog from "../../../_Components/Common/CustomDialog/CustomDialog";
  import {
    IoIosAddCircleOutline,
    IoIosRemoveCircleOutline,
  } from "react-icons/io";
  import { montArr } from "../../../_Components/Common/MonthArray";
  
  const DiscountForm = () => {
    const nToken = localStorage.getItem("nToken");
    const financialYear = localStorage.getItem("AY");
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const [classData, setClassData] = useState([]);
    const [monthData, setMonthData] = useState([]);
    const [customAlert, setCustomAlert] = useState(false);
    const [error, setError] = useState({});
  
    const [customDialogData, setCustomDialogData] = useState({
      titleHead: "",
      message: "",
    });

    const [formData, setFormData] = useState({
      getMonth: "",
      selectedMonths: [],
      discount: "",
      discountType: "",
      classSec: "",
    });
  
    const dispatch = useDispatch();
    const { classSectionData, classSectionLoading } = useSelector(
      (data) => data.classSectionInfo
    );
  
    const handleDurationChange = (duration) => {
      console.log(duration, "duration");
      let updatedMonths = [];
      let disableCustomSelection = false;
  
      switch (duration) {
        case 0:
          updatedMonths = []; // Let the user select any month
          break;
        case 1:
          disableCustomSelection = true; // Restrict selection to only one quarter
          break;
        case 2:
          disableCustomSelection = true; // Restrict selection to only one half-year
          break;
        case 3:
          updatedMonths = montArr.map((_, index) => index + 1); // Automatically select all months
          disableCustomSelection = true; // Prevent any changes
          break;
        default:
          break;
      }
  
      setFormData((prev) => ({
        ...prev,
        getMonth: duration, // Set the duration
        selectedMonths: updatedMonths, // Update months selection
        disableCustomSelection, // Whether the selection is restricted
      }));
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
  

  
    const handleAlertClose = () => {
      setCustomAlert(false);
    };
    useEffect(() => {
      if (!classSectionLoading) {
        dispatch(
          fetchClassSection({
            userinfo,
            nToken,
          })
        );
      }
    }, []);
  
    useEffect(() => {
      if (!classSectionLoading && classSectionData?.length > 0) {
        setClassData(classSectionData);
      }
    }, [classSectionLoading, classSectionData]);
  
    useEffect(() => {
      const getMonth = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/getFeeperiod`,
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          );
          setMonthData(response.data.feePeriodData);
        } catch (error) {
          console.log(error);
        }
      };
      getMonth();
    }, [nToken]);
  
    const validation = () => {
      let errors = {};
      if (!formData.getMonth) {
        errors.getMonth = "Please select a month";
      }
      if (!formData.classSec) {
        errors.classSec = "Please select a class";
      }
      if (!formData.discount) {
        errors.discount = "Please enter a discount";
      }
      setError(errors);
      return Object.keys(errors).length === 0;
    };
  
   
  
    const handleFormData = async (e) => {
      e.preventDefault();
     
      if (validation()) {
        try {
            const response = await axios.post(
            `${process.env.REACT_APP_nodewsPrefix}/classFeeDiscountAdd`,
            {
              schoolcode: userinfo[0].institution,
              academic_year: financialYear,
              discount: formData.discount,
              discount_type: formData.discountType,
              class_name: formData.classSec,
              fee_period_id: formData.getMonth,
              added_by: userinfo[0].id,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          );
          const successMessage = response?.data?.message;
          setCustomDialogData({
            titleHead: response?.data?.error ? "Failed" : "Success",
            message: successMessage,
          });
          setCustomAlert(true);
        } catch (error) {
          console.error("Error adding fee structure:", error);
        }
      }
    };
  
    const isFormEmpty =
      !formData.getMonth && !formData.discount && !formData.classSec;
  
    return (
      <>
        <Box component={"form"} onSubmit={handleFormData}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="classSecLabel">Class</InputLabel>
                <Select
                  labelId="classSecLabel"
                  id="classSec"
                  value={formData.classSec}
                  label="Class"
                  name="classSec"
                  onChange={handleChange}
                >
                  {Array.from(
                    new Set(
                      classData.map((obj) => obj.class).sort((a, b) => a - b)
                    )
                  ).map((data) => (
                    <MenuItem key={data} value={data}>
                      Class {data}
                    </MenuItem>
                  ))}
                </Select>
                {error.classSec && (
                  <Typography color="error">{error.classSec}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="duration-label">Duration</InputLabel>
                <Select
                  labelId="duration-label"
                  id="duration"
                  value={formData.getMonth}
                  label="Duration"
                  name="getMonth"
                  onChange={(event) => {
                    handleChange(event);
                    handleDurationChange(event.target.value);
                  }}
                >
                  {monthData.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.time_period}
                    </MenuItem>
                  ))}
                </Select>
                {error.getMonth && (
                  <Typography color="error">{error.getMonth}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="Months-label">Months</InputLabel>
                <Select
                  labelId="Months-label"
                  id="Months"
                  multiple
                  value={formData.selectedMonths || []}
                  onChange={(event) => {
                    const { value } = event.target;
                    let updatedMonths = value;
  
                    if (formData.getMonth === 1 && value.length > 0) {
                      const startMonth = Math.min(...value);
                      updatedMonths = [
                        startMonth,
                        startMonth + 1,
                        startMonth + 2,
                      ].filter((month) => month <= 12);
                    } else if (formData.getMonth === 2 && value.length > 0) {
                      const startMonth = Math.min(...value);
                      updatedMonths = [
                        startMonth,
                        startMonth + 1,
                        startMonth + 2,
                        startMonth + 3,
                        startMonth + 4,
                        startMonth + 5,
                      ].filter((month) => month <= 12);
                    } else if (formData.getMonth === 3) {
                      updatedMonths = montArr.map((_, index) => index + 1);
                    }
  
                    setFormData((prev) => ({
                      ...prev,
                      selectedMonths: updatedMonths,
                    }));
                  }}
                  name="selectedMonths"
                  label="Months"
                  renderValue={(selected) =>
                    selected.map((index) => montArr[index - 1]).join(", ")
                  }
                >
                  {montArr.map((data, index) => {
                    const monthIndex = index + 1; // Adjust for 1-based index
                    const isDisabled =
                      formData.getMonth === "quartely" ||
                      formData.getMonth === "half yearly"
                        ? formData.selectedMonths.length > 0 &&
                          !formData.selectedMonths.includes(monthIndex) &&
                          !formData.selectedMonths.some(
                            (selected) =>
                              monthIndex >= selected &&
                              monthIndex <
                                selected +
                                  (formData.getMonth === "quartely" ? 3 : 6)
                          )
                        : formData.getMonth === "annually"; // Disable all for "annually"
  
                    return (
                      <MenuItem
                        key={monthIndex}
                        value={monthIndex}
                        disabled={isDisabled}
                      >
                        <Checkbox
                          checked={formData.selectedMonths?.includes(monthIndex)}
                        />
                        <ListItemText primary={data} />
                      </MenuItem>
                    );
                  })}
                </Select>
                {error.selectedMonths && (
                  <Typography color="error">{error.selectedMonths}</Typography>
                )}
              </FormControl>
            </Grid>
  
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  id="discount"
                  label="Discount"
                  variant="outlined"
                  name="discount"
                  type="number"
                  value={formData.discount}
                  onChange={handleChange}
                />
                {error.discount && (
                  <Typography color="error">{error.discount}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  id="discountType"
                  label="Discount Type"
                  variant="outlined"
                  name="discountType"
                  type="text"
                  value={formData.discountType}
                  onChange={handleChange}
                />
                {error.discount && (
                  <Typography color="error">{error.discount}</Typography>
                )}
              </FormControl>
            </Grid>
          
          </Grid>
  
          <CenterButtonBoxArea mt={2}>
            <PrimaryBgBtn type="submit" disabled={isFormEmpty}>
              Submit
            </PrimaryBgBtn>
          </CenterButtonBoxArea>
        </Box>
  
        <CustomDialog
          isOpen={customAlert}
          cardTitle={customDialogData.titleHead}
          onClose={handleAlertClose}
        >
          <Box p={2} textAlign={"center"}>
            {customDialogData.message}
          </Box>
        </CustomDialog>
      </>
    );
  };
  
  export default DiscountForm;
  