import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import DevPermission from "../../_Components/Routing/DevPermission";
import MainCard from "../../_Components/Common/Cards/MainCard";

const DeveloperPermission = () => {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolInfo, setSchoolInfo] = useState([]);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );
        setSchoolInfo(response.data.info);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const handleSchoolChange = (event) => {
    const { value } = event.target;
    const selectedSchoolInfo = schoolInfo.find((data) => data.id === value);
    setSchoolName(selectedSchoolInfo);
    setSelectedSchool(value);
  };
  
  return (
    <MainCard cardTitle="Permission">
      <Box p={2}>
        <FormControl fullWidth>
          <InputLabel id="school-select-school">School</InputLabel>
          <Select
            labelid="school-select-school"
            id="school-select"
            name="selectedSchool"
            value={selectedSchool}
            input={<OutlinedInput label="school" />}
            onChange={handleSchoolChange}
          >
            {schoolInfo.map((data) => (
              <MenuItem key={data.id} value={data.id}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Typography>School Code: {data.id}</Typography>
                  <Typography>{data.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedSchool && (
        <DevPermission
          selectedSchool={selectedSchool}
          schoolName={schoolName}
        />
      )}
    </MainCard>
  );
};

export default DeveloperPermission;
