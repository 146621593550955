// src/FormComponent.jsx
import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import MainCard from "../Cards/MainCard";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import {
  LeftButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../Buttons/CustomBtns";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ViewModuleTable from "./ViewModuleTable";
import Loader from "../../Utils/loader/Loader";

const CreateModuleForm = () => {
  const [open, setOpen] = useState(false);
  const [ProceedPopup, setProceedPopup] = useState(false);
  const [formData, setFormData] = useState({
    formcode: "",
    formcomment: "",
    entries: "",
  });
  const [entry, setEntry] = useState([]);
  const [showCustomField, setShowCustomField] = useState(false);
  const [data, setData] = useState([]);
  const [disableTD, setDisableTD] = useState(false);
  const [ifedited, setIfEdited] = useState(false);
  const [ifloading, setIfloading] = useState(false);
  const [shModules, setShModules] = useState(false);
  const [resCreateTable, setResCreateTable] = useState("");
  const [PermissionPopup, setPermissionPopup] = useState(false);
  const nToken = localStorage.getItem("nToken");
  const getuserInfo = localStorage.getItem("userInfo");
  const getfinancialYear = localStorage.getItem("AY");
  var financialYear = JSON.parse(getfinancialYear);
  const userInfo = JSON.parse(getuserInfo);
  var schoolcode = userInfo[0].institution;

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePermissionPopup = () => {
    setPermissionPopup(false);
  };
  const handleCloseProceedPopup = () => {
    setProceedPopup(false);
    setFormData({ formcode: "", formcomment: "", entries: "" });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleEntryChange = (index, event) => {
    const { name, value } = event.target;
    const columnData = [...entry];
    console.log(columnData);
    columnData[index] = value;
    console.log(columnData, "ind");

    setEntry(columnData);
  };
  const addEntry = () => {
    console.log(entry, "ind1");
    setFormData({
      ...formData,
      // entries: [...formData.entries, ...entry],
    });
    console.log([...entry]);

    setEntry([...entry, " "]);
  };
  const removeRow = (index) => {
    const newFormValues = entry.filter((_, i) => i !== index);
    setEntry(newFormValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    setProceedPopup(true);
    setFormData({
      ...formData,
    });
    console.log(entry);
    setFormData({ ...formData, formcode: "", formcomment: "" });
    submit();
    async function submit() {
      setIfloading(true);
      if (ifedited) {
        const url = `${process.env.REACT_APP_nodewsPrefix}/createTableDynamic`;
        let res = await axios.post(
          url,
          {
            title: formData.formcode.replaceAll(/ /g, "_"),
            schoolcode: schoolcode,
            description: formData.formcomment,
            customColumn: IDS,
            columnName: entry,
            edit: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        setFormData({ formcode: formData.formcode });
        setIfloading(false);
        setStudent([]);
        setTeacher([]);
        setEntry([]);
        console.log(res);
        setResCreateTable(res.data.message);
      } else {
        const url = `${process.env.REACT_APP_nodewsPrefix}/createTableDynamic`;
        let res = await axios.post(
          url,
          {
            title: formData.formcode.replaceAll(/ /g, "_"),
            schoolcode: schoolcode,
            description: formData.formcomment,
            customColumn: IDS,
            columnName: entry,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        setFormData({ formcode: formData.formcode });
        setIfloading(false);
        setStudent([]);
        setTeacher([]);
        setEntry([]);
        setResCreateTable(res.data.message);
        // console.log(res);
      }
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    const url = `${process.env.REACT_APP_nodewsPrefix}/viewColumnMapping?schoolcode=${schoolcode}&academic_year=${financialYear}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setData(response.data.column_data);
      });
  }, []);
  console.log(data);

  const StudentDetails = data
    .filter((item) => {
      return item.section === "student";
    })
    .map((value) => {
      return value.section_data;
    });
  const TeacherDetails = data
    .filter((item) => {
      return item.section === "teacher";
    })
    .map((value) => {
      return value.section_data;
    });

  const [student, setStudent] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const handleChangeStd = (event) => {
    const {
      target: { value },
    } = event;
    setStudent(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeTech = (event) => {
    const {
      target: { value },
    } = event;
    setTeacher(typeof value === "string" ? value.split(",") : value);
  };
  console.log(student, teacher);
  const findIdss = (data, student) => {
    return data
      .filter((item) => student.includes(item.section_data))
      .map((item) => item.id);
  };

  const matchingIds = findIdss(data, student);
  console.log(matchingIds);
  const findIdt = (data, teacher) => {
    return data
      .filter((item) => teacher.includes(item.section_data))
      .map((item) => item.id);
  };

  const matchingIdt = findIdt(data, teacher);
  console.log(matchingIdt);
  const IDS = matchingIds.concat(matchingIdt);
  console.log(IDS);

  const handleCustomEntryClick = () => {
    setShowCustomField(true);
    setEntry([""]);
  };
  const reciveChildToParent = (data) => {
    const editedStudentData = data.customColumn
      .filter((item) => {
        return item.section === "student";
      })
      .map((values) => {
        return values.section_data;
      });

    const editedTeacherData = data.customColumn
      .filter((item) => {
        return item.section === "teacher";
      })
      .map((values) => {
        return values.section_data;
      });

    console.log(editedStudentData, editedTeacherData);
    setStudent(
      typeof editedStudentData === "string"
        ? editedStudentData.split(",")
        : editedStudentData
    );
    setTeacher(
      typeof editedTeacherData === "string"
        ? editedTeacherData.split(",")
        : editedTeacherData
    );
    setEntry(data.columnName);
    setFormData({ formcode: data.title, formcomment: data.description });
    setShowCustomField(data.columnName.length);
    setDisableTD(true);
    setIfEdited(true);
  };
  const handleChangeModule = () => {
    setShModules((prevState) => !prevState);
  };

  return (
    <>
      <MainCard cardTitle="Module" customClass="">
        {/* <Button onClick={permissionPop}>Permission</Button> */}
        {ifloading ? (
          <Loader />
        ) : (
          <form onSubmit={handleClickOpen}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Title"
                  name="formcode"
                  value={formData.formcode}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  disabled={disableTD}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Description"
                  name="formcomment"
                  value={formData.formcomment}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  disabled={disableTD}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                border: "1px solid #ccc",
                padding: 2,
                borderRadius: 2,
                marginTop: 2,
              }}
            >
              <Typography variant="subtitle1">Add Column Entry</Typography>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={4}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Student Data
                    </InputLabel>
                    <Select
                      labelid="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={student}
                      onChange={handleChangeStd}
                      input={<OutlinedInput label="Student Data" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {StudentDetails.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={student.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Teacher Data
                    </InputLabel>
                    <Select
                      labelid="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={teacher}
                      onChange={handleChangeTech}
                      input={<OutlinedInput label="Teacher Data" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {TeacherDetails.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={teacher.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <PrimaryBgBtn sx={{ m: 1 }} onClick={handleCustomEntryClick}>
                    Custom Entry
                  </PrimaryBgBtn>
                </Grid>
              </Grid>
              {console.log(entry, "ind3")}
              {showCustomField &&
                entry &&
                entry.map((entryData, index) => (
                  <Grid container spacing={1} mt={1} key={index}>
                    <Grid item md={2}>
                      <TextField
                        label="Field Name"
                        name="columnName"
                        value={entryData}
                        onChange={(event) => handleEntryChange(index, event)}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 20 }}
                        required
                      />
                    </Grid>

                    {
                      <IconButton
                        aria-label="Delete row"
                        sx={{ padding: "2px" }}
                      >
                        <AiOutlineMinusCircle
                          onClick={() => removeRow(index)}
                          fontSize={26}
                        />
                      </IconButton>
                    }

                    {entry && (
                      <IconButton
                        aria-label="Add row for other class or subject"
                        sx={{ padding: "1px" }}
                      >
                        <AiOutlinePlusCircle onClick={addEntry} fontSize={26} />
                      </IconButton>
                    )}
                  </Grid>
                ))}
            </Box>
            <LeftButtonBoxArea sx={{ marginTop: "20px" }}>
              <PrimaryOutlineBtn onClick={handleChangeModule}>
                {shModules ? "Hide All Modules" : "Show All Modules"}
              </PrimaryOutlineBtn>
              <PrimaryBgBtn type="submit">
                {ifedited ? "Save Edited " : "Submit"}
              </PrimaryBgBtn>
            </LeftButtonBoxArea>

            {/* <NavLink to="/viewModules" style={{ marginLeft: 4}} className="btn primaryBtn">View All Modules</NavLink> */}
          </form>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning:"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to save data, please ensure all information is
              correct; this action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryOutlineBtn onClick={handleClose}>Cancel</PrimaryOutlineBtn>
            <PrimaryBgBtn onClick={handleSubmit}>Submit</PrimaryBgBtn>
          </DialogActions>
        </Dialog>
        {resCreateTable && (
          <Dialog
            open={ProceedPopup}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {formData.formcode}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* Table Created Successfully. */}
                {resCreateTable}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <PrimaryBgBtn onClick={handleCloseProceedPopup}>OK</PrimaryBgBtn>
            </DialogActions>
          </Dialog>
        )}
      </MainCard>
      {shModules
        ? !ifloading && <ViewModuleTable editData={reciveChildToParent} />
        : ""}
    </>
  );
};
export default CreateModuleForm;
