import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import Loader from "../../../_Components/Utils/loader/Loader";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";

function QuestionListing({ categoryID }) {
  const [searchParams] = useSearchParams();
  const moduleID = searchParams.get("moduleID");
  const courseid = searchParams.get("courseid");
  const [listingLoading, setListingLoading] = useState(false);
  const [questionList, setQuestionList] = useState({ status: "", data: [] });
  const [rows, setRows] = useState([]);
  const editQ = searchParams.get("editing");

  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const navigate = useNavigate();
  const [questionDeleted, setQuestionDeleted] = useState({
    success: false,
    message: "",
  });

  const columns = [
    { field: "id", headerName: "Sr.No", width: 90 },
    { field: "question", headerName: "Question", width: 500 },
    { field: "type", headerName: "Question Type", width: 150 },
    {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValue) => {
        return (
          <Tooltip title="Edit">
            <Button
              className="circleBtn"
              sx={{ backgroundColor: "iconBg.main", color: "iconBg.contrast" }}
              onClick={() => {
                navigate(
                  `/addquestion?editing=true&questionID=${cellValue.row.id}&courseID=${courseid}&categoryID=${categoryID}&moduleID=${moduleID}`
                );
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 50,
      renderCell: (cellValue) => {
        return (
          <Tooltip title="Delete">
            <Button
              className="circleBtn"
              sx={{ backgroundColor: "iconBg.main", color: "iconBg.contrast" }}
              onClick={() => {
                deleteQuestionHandler(cellValue.id);
              }}
            >
              <RiDeleteBin6Line />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const deleteQuestionHandler = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/questionDelete`,
        {
          questionId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setQuestionDeleted({
          success: true,
          message: "Question deleted successfully",
        });
        getQuestionsList();
        setTimeout(() => {
          setQuestionDeleted({ success: false, message: "" });
        }, 2000);
      })
      .catch((err) => {
        setQuestionDeleted({
          success: false,
          message: "Some problem can not delete question",
        });
      });
  };

  const getQuestionsList = () => {
    setListingLoading(true);
    const questionListURL = `${process.env.REACT_APP_nodewsPrefix}/quizQuestionList?courseID=${courseid}&quizID=${moduleID}`;
    axios
      .get(questionListURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setListingLoading(false);
        setQuestionList({ status: "done", data: response.data });
        let settingRow = response.data.questionData.map((question, indx) => {
          return {
            id: question.id,
            question: question.questiontext,
            type: question.qtype,
          };
        });
        setRows(settingRow);
      })
      .catch((err) => {
        console.log("error");
        setQuestionList({ status: "error", data: [] });
      });
  };

  const addQuestion = () => {
    const addQuestionURL = `/addquestion?quizID=${moduleID}&catID=12225&courseID=${courseid}`;
    navigate(addQuestionURL);
  };

  useEffect(() => {
    if (editQ === "true") {
      getQuestionsList();
    }
  }, []);

  return (
    <>
      <Typography
        className="title"
        variant="h5"
        px={2}
        py={1}
        sx={{ borderBottom: "0 !important", mb: 2 }}
      >
        <Box
          className="titleHead"
          sx={{
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Questions List</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Add More Question">
              <IconButton>
                <AddCircleOutlineIcon onClick={addQuestion} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Typography>

      <Box>
        {listingLoading ? (
          <Loader />
        ) : (
          questionList.data.questionData &&
          (questionList.data.questionData.length > 0 ? (
            <DataGrid
              rows={rows}
              sx={{ ml: 2, mr: 2, mb: 2 }}
              columns={columns}
              checkboxSelection={false}
              disableRowSelectionOnClick={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          ) : (
            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>No question in this quiz</Box>
          ))
        )}
      </Box>
      <Box sx={{ mb: 2, ml: 2 }}>{questionDeleted.message}</Box>
    </>
  );
}

export default QuestionListing;
