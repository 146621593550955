import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./Admission.module.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdOutlineUploadFile } from "react-icons/md";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { languages } from "../../App";
import PropTypes from "prop-types";
import { AdmissionInfo } from "./Data";
import Popupclose from "../../_Components/Utils/Popup/PopupClose";
import MainCard from "../../_Components/Common/Cards/MainCard";

//tabs section Start here
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
//tabs section end here

function AdmissionForm() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [tabValid, setTabValid] = useState(false);
  const [admissioninfo, setAdmissioninfo] = useState({
    registrationNo: "",
    firstName: "",
    lastName: "",
    class: "",
    dob: "",
    birthcertificate: "",
    bloodGroup: "",
    gender: "",
    userImgfile: "",
    nationality: "",
    religion: "",
    category: "",
    ews: "",
    ewsfile: "",
    siblingInfo: "",
    transportInfo: "",
    admissionno: "",
    siblingname: "",
    siblingclass: "",
    section: "",
    feereceipt: "",
    guardian: "",
    relation: "",
    lastschool: "",
    schooladdress: "",
    physically: "",
    mentally: "",
    descriptionIllness: "",
    salutation: "",
    fullname: "",
    highqualification: "",
    occupation: "",
    annualIncome: "",
    officeAddress: "",
    phoneNumber: "",
    mobile: "",
    contactNo: "",
    email: "",
    panfile: "",
    aadharfile: "",
    salutation2: "",
    fullname2: "",
    highqualification2: "",
    occupation2: "",
    annualIncome2: "",
    officeAddress2: "",
    phoneNumber2: "",
    mobile2: "",
    contactNo2: "",
    email2: "",
    panfile2: "",
    aadharfile2: "",
    addressl1: "",
    addressl2: "",
    ccountry: "",
    cstate: "",
    ccity: "",
    cpincode: "",
    paddressl1: "",
    paddressl2: "",
    pcountry: "",
    pstate: "",
    ppcity: "",
    ppincode: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlenextClick = (e) => {
    let val = e.target.name === "back" ? value + 1 : value - 1;
    setValue(val);
  };

  let curLanguage = useContext(languages);
  const [checked, setChecked] = useState(false);
  const [path, setPath] = useState({
    adharcard: null,
    photo: null,
    document: null,
    userImage: null,
  });

  const handleSubmit = (e, required) => {
    e.preventDefault();
    if (validateForm()) {
      const randomNum = Math.floor(Math.random() * 10000000);
      alert(`RSPL${randomNum}`);
    } else {
      alert("Please fill all data to submit a form");
    }

    const isValid = {};
    if (required !== "") {
      setTabValid(isValid);
    }
    return isValid;
  };

  // const validateField = (fieldName, value) => {
  //   if (value.trim() === "") {
  //     return `${fieldName} is required`;
  //   }
  //   // Add more specific validation rules if needed
  //   return "";
  // };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate username
    if (admissioninfo.firstName.trim() === "") {
      newErrors.firstName = "firstName is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // const validateForm = () => {
  //   let isValid = true;
  //   const newErrors = {};

  //   // Iterate through each field in formData
  //   for (const [fieldName, value] of Object.entries(admissioninfo)) {
  //     const error = validateField(fieldName, value);
  //     if (error) {
  //       newErrors[fieldName] = error;
  //       isValid = false;
  //     }
  //   }

  //   setErrors(newErrors);
  //   return isValid;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (e.target.name === "siblingInfo") {
      let val =
        admissioninfo.siblingInfo === "checked" ? "unchecked" : "checked";
      setAdmissioninfo({ ...admissioninfo, [name]: val });
      return;
    }
    if (e.target.name === "illness") {
      let val1 = admissioninfo.illness === "checked" ? "unchecked" : "checked";
      setAdmissioninfo({ ...admissioninfo, [name]: val1 });
      return;
    }

    setAdmissioninfo({ ...admissioninfo, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setTabValid(false);
  };

  const uploadFile = (event) => {
    const formData = new FormData();
    let id = event.target.id;
    formData.append(
      event.target.name,
      event.target.files[0],
      event.target.files[0].name
    );
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/admission/${event.target.id}`,
        formData
      )
      .then((response) => {
        setPath({ ...path, [id]: response.data });
      });
  };

  const createImage = (type) => {
    return (
      <div className={styles.uploadImg}>
        <AiOutlineCloseCircle
          iType={type}
          onClick={(e) => {
            setPath({ ...path, [e.currentTarget.getAttribute("iType")]: null });
          }}
          style={{ cursor: "pointer" }}
        />
        <img
          alt="uploded File"
          src={`${process.env.REACT_APP_nodewsPrefix}/${path[type]}`}
        />
      </div>
    );
  };
  const [ewsdata, setEwsdata] = useState(false);
  function handleEwsData() {
    setEwsdata(!ewsdata);
  }

  const reghandleChange = (e) => {
    if (e.target.value === "123") {
      setAdmissioninfo(AdmissionInfo[0]);
    }
  };

  return (
    <MainCard cardTitle={curLanguage.admissionform}>
      <Box className={styles.admission_FormWrapper}>
        <form onSubmit={handleSubmit} className={styles.admission_Form}>
          <Box className={`${styles.admission_Card}`}>
            {/* tabs section start here */}
            <Box sx={{ backgroundColor: "cardColor.main" }}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={tabHandleChange}
                  indicatorColor="iconColor.color"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  sx={{
                    backgroundColor: "tab.bgColor",
                    "& .Mui-selected": {
                      backgroundColor: "iconColor.color",
                      fontWeight: 700,
                      letterSpacing: "0.5px",
                    },
                  }}
                >
                  <Tab
                    label={curLanguage.studentdetail}
                    {...a11yProps(0)}
                    className={tabValid ? "error" : ""}
                  />
                  <Tab label={curLanguage.parentdetails} {...a11yProps(1)} />
                  <Tab label={curLanguage.address} {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {/* Student Details start here */}
                <Grid container spacing={2}>
                  <Grid item xl={10.5} md={10} xs={12}>
                    <Box className={styles.admission_FormContent}>
                      <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            label={curLanguage.registrationNo}
                            name="registrationNo"
                            fullWidth
                            margin="normal"
                            onChange={reghandleChange}
                            className={styles.customWidth}
                          />
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                          <TextField
                            value={admissioninfo.firstName}
                            label={curLanguage.fname}
                            name="firstName"
                            fullWidth
                            margin="normal"
                            required
                            onChange={handleChange}
                            className={styles.customWidth}
                            style={{
                              border: errors.firstName ? "1px solid red" : "",
                              borderRadius: "5px",
                            }}
                          />
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                          <TextField
                            value={admissioninfo.lastName}
                            label={curLanguage.lname}
                            name="lastName"
                            fullWidth
                            margin="normal"
                            onChange={handleChange}
                            className={styles.customWidth}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <FormControl className={styles.customWidth}>
                            <InputLabel id="demo-simple-select-helper-label">
                              {curLanguage.class}
                            </InputLabel>
                            <Select
                              labelid="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={admissioninfo.class}
                              name="class"
                              label={curLanguage.class}
                              onChange={handleChange}
                            >
                              <MenuItem value="LKG">LKG</MenuItem>
                              <MenuItem value="UKG">UKG</MenuItem>
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={11}>11</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} xs={8}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={curLanguage.dob}
                              onChange={handleChange}
                              className={styles.customWidth}
                              name="dob"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={1} xs={4}>
                          <Box className={styles.d_flex}>
                            <Box
                              className={styles.customUpload}
                              sx={{
                                color: "icons.textColor",
                                borderColor: "icons.textColor",
                              }}
                            >
                              <label htmlFor="dobCertificate">
                                <MdOutlineUploadFile />
                                <span>{curLanguage.birthCertificate}</span>
                              </label>
                            </Box>
                            <Box className={styles.uploadImg}>
                              {path.adharcard != null
                                ? createImage("adharcard")
                                : ""}
                            </Box>
                          </Box>
                          <TextField
                            type="file"
                            name="birthcertificate"
                            fullWidth
                            margin="normal"
                            required
                            inputProps={{
                              accept: "image/png, image/gif, image/jpeg",
                            }}
                            onChange={uploadFile}
                            className={`${styles.customWidth} ${styles.dNone}`}
                            id="dobCertificate"
                          />
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <FormControl className={styles.customWidth}>
                            <InputLabel id="demo-simple-blood">
                              {curLanguage.blood}
                            </InputLabel>
                            <Select
                              labelid="demo-simple-blood"
                              id="demo-simple-select-helper"
                              name="bloodGroup"
                              label={curLanguage.bloodGroup}
                              value={admissioninfo.bloodGroup}
                              onChange={handleChange}
                            >
                              <MenuItem value="O+">
                                {curLanguage.blood1}
                              </MenuItem>
                              <MenuItem value="O-">
                                {curLanguage.blood2}
                              </MenuItem>
                              <MenuItem value="A+">
                                {" "}
                                {curLanguage.blood3}
                              </MenuItem>
                              <MenuItem value="A-">
                                {curLanguage.blood4}
                              </MenuItem>
                              <MenuItem value="B+">
                                {curLanguage.blood5}
                              </MenuItem>
                              <MenuItem value="B-">
                                {curLanguage.blood6}
                              </MenuItem>
                              <MenuItem value="AB+">
                                {" "}
                                {curLanguage.blood7}
                              </MenuItem>
                              <MenuItem value="AB-">
                                {curLanguage.blood8}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControl className={styles.customWidth}>
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              sx={{ color: "labelColor.color" }}
                            >
                              {curLanguage.gender}
                            </FormLabel>

                            <RadioGroup
                              row
                              aria-labelledby="gender"
                              name="gender"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                checked={admissioninfo.gender === "female"}
                                value="female"
                                control={<Radio />}
                                label={curLanguage.female}
                              />
                              <FormControlLabel
                                checked={admissioninfo.gender === "male"}
                                value="male"
                                control={<Radio />}
                                label={curLanguage.male}
                              />
                              <FormControlLabel
                                checked={admissioninfo.gender === "other"}
                                value="other"
                                control={<Radio />}
                                label={curLanguage.other}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    md={2}
                    xs={12}
                    className={styles.imageUploadSec}
                  >
                    <Box
                      className={styles.imageUpload}
                      sx={{
                        color: "icons.textColor",
                        borderColor: "icons.textColor",
                      }}
                    >
                      <label
                        htmlFor="userImage"
                        style={{ color: "icons.textColor" }}
                      >
                        <MdOutlineUploadFile />
                        <p>{curLanguage.image}</p>
                        <p>{curLanguage.imgsize}</p>
                      </label>
                      <Box className={styles.userImg}>
                        {path.userImage != null ? createImage("userImage") : ""}
                      </Box>
                    </Box>
                    <TextField
                      type="file"
                      name="userImgfile"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{
                        accept: "image/png, image/gif, image/jpeg",
                      }}
                      onChange={uploadFile}
                      className={`${styles.customWidth} ${styles.dNone}`}
                      id="userImage"
                    />
                  </Grid>
                </Grid>

                <Box className={styles.admission_FormContent}>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <FormControl className={styles.customWidth}>
                        <InputLabel id="demo-simple-nationality">
                          {curLanguage.nationality}
                        </InputLabel>
                        <Select
                          labelid="demo-simple-nationality"
                          id="demo-simple-select-helper"
                          name="nationality"
                          value={admissioninfo.nationality}
                          label={curLanguage.nationality}
                          onChange={handleChange}
                        >
                          <MenuItem value="Indian">India</MenuItem>
                          <MenuItem value="American">America</MenuItem>
                          <MenuItem value="Turkey">Turkey</MenuItem>
                          <MenuItem value="Cannada">Cannada</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl className={styles.customWidth}>
                        <InputLabel id="demo-simple-religion">
                          {curLanguage.religion}
                        </InputLabel>
                        <Select
                          labelid="demo-simple-religion"
                          id="demo-simple-select-helper1"
                          name="religion"
                          value={admissioninfo.religion}
                          label={curLanguage.religion}
                          onChange={handleChange}
                        >
                          <MenuItem value="Hindu">{curLanguage.hindu}</MenuItem>
                          <MenuItem value="Muslim">
                            {curLanguage.muslim}
                          </MenuItem>
                          <MenuItem value="Christian">
                            {curLanguage.christian}
                          </MenuItem>
                          <MenuItem value="Other">{curLanguage.other}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl className={styles.customWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {curLanguage.category}
                        </InputLabel>
                        <Select
                          labelid="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="category"
                          value={admissioninfo.category}
                          label={curLanguage.category}
                          onChange={handleChange}
                        >
                          <MenuItem value="General">
                            {curLanguage.general}
                          </MenuItem>
                          <MenuItem value="OBC">{curLanguage.obc}</MenuItem>
                          <MenuItem value="SC">{curLanguage.sc}</MenuItem>
                          <MenuItem value="ST">{curLanguage.st}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={curLanguage.ews}
                          control={<Checkbox />}
                          labelPlacement="end"
                          onChange={handleEwsData}
                          name="ews"
                          checked={admissioninfo.ews === "checked"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={1} xs={2}>
                      {ewsdata && (
                        <>
                          <Box className={styles.d_flex}>
                            <Box
                              className={styles.customUpload}
                              sx={{
                                color: "icons.textColor",
                                borderColor: "icons.textColor",
                              }}
                            >
                              <label htmlFor="ews">
                                <MdOutlineUploadFile />
                                <span>{curLanguage.ewscertificate}</span>
                              </label>
                            </Box>
                            <Box className={styles.uploadImg}>
                              {path.adharcard != null
                                ? createImage("adharcard")
                                : ""}
                            </Box>
                          </Box>
                          <TextField
                            type="file"
                            name="ewsfile"
                            value={admissioninfo.ewsfile}
                            fullWidth
                            margin="normal"
                            required
                            inputProps={{
                              accept: "image/png, image/gif, image/jpeg",
                            }}
                            onChange={uploadFile}
                            className={`${styles.customWidth} ${styles.dNone}`}
                            id="ews"
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={curLanguage.sibling}
                          control={<Checkbox />}
                          labelPlacement="end"
                          onChange={handleChange}
                          name="siblingInfo"
                          checked={admissioninfo.siblingInfo === "checked"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={curLanguage.transport}
                          control={<Checkbox />}
                          labelPlacement="end"
                          name="transportInfo"
                          onChange={handleChange}
                          value={admissioninfo.transportInfo}
                        />
                      </FormGroup>
                    </Grid>
                    {admissioninfo.siblingInfo === "checked" && (
                      <Grid item md={12} xs={12}>
                        <TableContainer>
                          <Table
                            sx={{
                              minWidth: 650,
                              borderColor: "icons.textColor",
                            }}
                            aria-label="simple table"
                            border="1"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b>{curLanguage.admissionno}</b>
                                </TableCell>
                                <TableCell>
                                  <b>{curLanguage.name}</b>
                                </TableCell>
                                <TableCell>
                                  <b>{curLanguage.class}</b>
                                </TableCell>
                                <TableCell>
                                  <b>{curLanguage.section}</b>
                                </TableCell>
                                <TableCell>
                                  <b>{curLanguage.feereceipt}</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    label={curLanguage.admissionno}
                                    name="admissionno"
                                    value={admissioninfo.admissionno}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    className={styles.customWidth}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    required
                                    label={curLanguage.name}
                                    name="siblingname"
                                    value={admissioninfo.siblingname}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    className={styles.customWidth}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label={curLanguage.class}
                                    name="siblingclass"
                                    value={admissioninfo.siblingclass}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    className={styles.customWidth}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label={curLanguage.section}
                                    name="section"
                                    value={admissioninfo.section}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    className={styles.customWidth}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label={curLanguage.feereceipt}
                                    name="feereceipt"
                                    value={admissioninfo.feereceipt}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    className={styles.customWidth}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}

                    <Grid item md={4} xs={12}>
                      <TextField
                        label={curLanguage.guardian}
                        name="guardian"
                        value={admissioninfo.guardian}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        label={curLanguage.relation}
                        name="relation"
                        value={admissioninfo.relation}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        label={curLanguage.schollname}
                        name="lastschool"
                        value={admissioninfo.lastschool}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        label={curLanguage.schoolAddress}
                        name="schooladdress"
                        value={admissioninfo.schooladdress}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={curLanguage.physical}
                          control={<Checkbox />}
                          labelPlacement="end"
                          name="physically"
                          value={admissioninfo.physically}
                        />
                      </FormGroup>
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        label={curLanguage.mentally}
                        control={<Checkbox />}
                        labelPlacement="end"
                        name="mentally"
                        value={admissioninfo.mentally}
                      />
                    </FormGroup>
                  </Grid> */}
                    <Grid item md={4} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={curLanguage.illness}
                          control={<Checkbox />}
                          labelPlacement="end"
                          onChange={handleChange}
                          name="illness"
                          checked={admissioninfo.illness === "checked"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {admissioninfo.illness === "checked" && (
                        <TextField
                          label={curLanguage.descriptionIllness}
                          name="descriptionIllness"
                          fullWidth
                          margin="normal"
                          multiline
                          rows={2}
                          onChange={handleChange}
                          className={styles.customWidth}
                          value={admissioninfo.descriptionIllness}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    name="back"
                    color="secondaryColor"
                    onClick={handlenextClick}
                    className="btn primaryBtn"
                    sx={{ display: "flex" }}
                  >
                    {curLanguage.next}
                  </Button>
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box className={styles.admission_FormContent}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "textsColor.color", paddingLeft: "0" }}
                  >
                    <b>{curLanguage.Parentsdetail}</b>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="salutation"
                        value={admissioninfo.salutation}
                        label={curLanguage.salutation}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="fullname"
                        value={admissioninfo.fullname}
                        label={curLanguage.fullname}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl className={styles.customWidth}>
                        <InputLabel id="demo-simple-highqualification">
                          {curLanguage.highqualification}
                        </InputLabel>
                        <Select
                          labelid="demo-simple-highqualification"
                          id="demo-simple-select-helper"
                          name="highqualification"
                          value={admissioninfo.highqualification}
                          label="Highest Qualification"
                          onChange={handleChange}
                        >
                          <MenuItem value="Secondary">Secondary</MenuItem>
                          <MenuItem value="Higher">Higher Secondary</MenuItem>
                          <MenuItem value="Bachelor">
                            Bachelor's Degree
                          </MenuItem>
                          <MenuItem value="Diploma">Diploma</MenuItem>
                          <MenuItem value="Certificate">Certificate</MenuItem>
                          <MenuItem value="Postgraduate">Postgraduate</MenuItem>
                          <MenuItem value="phd">PHD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="occupation"
                        value={admissioninfo.occupation}
                        label={curLanguage.occupation}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        inputmode="numeric"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="annualIncome"
                        value={admissioninfo.annualIncome}
                        label={curLanguage.annualIncome}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="officeAddress"
                        value={admissioninfo.officeAddress}
                        label={curLanguage.officeAddress}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="phoneNumber"
                        value={admissioninfo.phoneNumber}
                        label={curLanguage.phoneNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="mobile"
                        value={admissioninfo.mobile}
                        label={curLanguage.mobile}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="contactNo"
                        value={admissioninfo.contactNo}
                        label={curLanguage.contactNo}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="email"
                        value={admissioninfo.email}
                        label={curLanguage.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box className={styles.d_flex}>
                        <Box
                          className={styles.customUpload}
                          sx={{
                            color: "icons.textColor",
                            borderColor: "icons.textColor",
                          }}
                        >
                          <label htmlFor="panCard">
                            <MdOutlineUploadFile />
                            <span>{curLanguage.pancard}</span>
                          </label>
                        </Box>
                        <Box className={styles.uploadImg}>
                          {path.adharcard != null
                            ? createImage("adharcard")
                            : ""}
                        </Box>
                      </Box>
                      <TextField
                        type="file"
                        name="panfile"
                        fullWidth
                        margin="normal"
                        required
                        inputProps={{
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={uploadFile}
                        className={`${styles.customWidth} ${styles.dNone}`}
                        id="panCard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box className={styles.d_flex}>
                        <Box
                          className={styles.customUpload}
                          sx={{
                            color: "icons.textColor",
                            borderColor: "icons.textColor",
                          }}
                        >
                          <label htmlFor="adharcard">
                            <MdOutlineUploadFile />
                            <span>{curLanguage.aadhar}</span>
                          </label>
                        </Box>
                        <Box className={styles.uploadImg}>
                          {path.adharcard != null
                            ? createImage("adharcard")
                            : ""}
                        </Box>
                      </Box>
                      <TextField
                        type="file"
                        name="aadharfile"
                        fullWidth
                        margin="normal"
                        required
                        inputProps={{
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={uploadFile}
                        className={`${styles.customWidth} ${styles.dNone}`}
                        id="adharcard"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={styles.admission_FormContent}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "textsColor.color", paddingLeft: "0" }}
                  ></Typography>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="salutation2"
                        value={admissioninfo.salutation2}
                        label={curLanguage.salutation}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="fullname2"
                        value={admissioninfo.fullname2}
                        label={curLanguage.fullname}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl className={styles.customWidth}>
                        <InputLabel id="demo-simple-highqualification">
                          {curLanguage.highqualification}
                        </InputLabel>
                        <Select
                          labelid="demo-simple-highqualification"
                          id="demo-simple-select-helper"
                          name="highqualification2"
                          value={admissioninfo.highqualification2}
                          label="Highest Qualification"
                          onChange={handleChange}
                        >
                          <MenuItem value="Secondary">Secondary</MenuItem>
                          <MenuItem value="Higher">Higher Secondary</MenuItem>
                          <MenuItem value="Bachelor">
                            Bachelor's Degree
                          </MenuItem>
                          <MenuItem value="Diploma">Diploma</MenuItem>
                          <MenuItem value="Certificate">Certificate</MenuItem>
                          <MenuItem value="Postgraduate">Postgraduate</MenuItem>
                          <MenuItem value="phd">PHD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="occupation2"
                        value={admissioninfo.occupation2}
                        label={curLanguage.occupation}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        inputmode="numeric"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="annualIncome2"
                        value={admissioninfo.annualIncome2}
                        label={curLanguage.annualIncome}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="officeAddress2"
                        value={admissioninfo.officeAddress2}
                        label={curLanguage.officeAddress}
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="phoneNumber2"
                        value={admissioninfo.phoneNumber2}
                        label={curLanguage.phoneNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="mobile2"
                        value={admissioninfo.mobile2}
                        label={curLanguage.mobile}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="contactNo2"
                        value={admissioninfo.contactNo2}
                        label={curLanguage.contactNo}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="email2"
                        value={admissioninfo.email2}
                        label={curLanguage.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box className={styles.d_flex}>
                        <Box
                          className={styles.customUpload}
                          sx={{
                            color: "icons.textColor",
                            borderColor: "icons.textColor",
                          }}
                        >
                          <label htmlFor="panCard">
                            <MdOutlineUploadFile />
                            <span>{curLanguage.pancard}</span>
                          </label>
                        </Box>
                        <Box className={styles.uploadImg}>
                          {path.adharcard != null
                            ? createImage("adharcard")
                            : ""}
                        </Box>
                      </Box>
                      <TextField
                        type="file"
                        name="panfile2"
                        fullWidth
                        margin="normal"
                        required
                        inputProps={{
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={uploadFile}
                        className={`${styles.customWidth} ${styles.dNone}`}
                        id="panCard"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box className={styles.d_flex}>
                        <Box
                          className={styles.customUpload}
                          sx={{
                            color: "icons.textColor",
                            borderColor: "icons.textColor",
                          }}
                        >
                          <label htmlFor="adharcard">
                            <MdOutlineUploadFile />
                            <span>{curLanguage.aadhar}</span>
                          </label>
                        </Box>
                        <Box className={styles.uploadImg}>
                          {path.adharcard != null
                            ? createImage("adharcard")
                            : ""}
                        </Box>
                      </Box>
                      <TextField
                        type="file"
                        name="aadharfile2"
                        fullWidth
                        margin="normal"
                        required
                        inputProps={{
                          accept: "image/png, image/gif, image/jpeg",
                        }}
                        onChange={uploadFile}
                        className={`${styles.customWidth} ${styles.dNone}`}
                        id="adharcard"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    name=""
                    color="secondaryColor"
                    onClick={handlenextClick}
                    className="btn"
                    sx={{ display: "flex", minWidth: "90px !important" }}
                  >
                    {curLanguage.previous}
                  </Button>
                  <Button
                    variant="contained"
                    name="back"
                    color="secondaryColor"
                    onClick={handlenextClick}
                    className="btn"
                    sx={{ display: "flex", minWidth: "90px !important" }}
                  >
                    {curLanguage.next}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box className={styles.admission_FormContent}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "textsColor.color", paddingLeft: "0" }}
                  >
                    <b>{curLanguage.current}</b>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label={curLanguage.addressline1}
                        onChange={handleChange}
                        name="addressl1"
                        value={admissioninfo.addressl1}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label={curLanguage.addressline2}
                        onChange={handleChange}
                        name="addressl2"
                        value={admissioninfo.addressl2}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label={curLanguage.country}
                        onChange={handleChange}
                        name="ccountry"
                        value={admissioninfo.ccountry}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label={curLanguage.state}
                        onChange={handleChange}
                        name="cstate"
                        value={admissioninfo.cstate}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label={curLanguage.city}
                        onChange={handleChange}
                        name="ccity"
                        value={admissioninfo.ccity}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label={curLanguage.pincode}
                        onChange={handleChange}
                        name="cpincode"
                        value={admissioninfo.cpincode}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ maxHeight: "50px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={curLanguage.selectBoth}
                        onChange={(e) => setChecked(e.target.checked)}
                        sx={{ mb: 1 }}
                      />
                    </FormGroup>
                  </Grid>
                </Box>
                <Box className={styles.admission_FormContent}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "textsColor.color", paddingLeft: "0" }}
                  >
                    <b>{curLanguage.permanent}</b>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.addressline1}
                        onChange={handleChange}
                        name="paddressl1"
                        value={admissioninfo.paddressl1}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.addressline2}
                        onChange={handleChange}
                        name="paddressl2"
                        value={admissioninfo.paddressl2}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.country}
                        onChange={handleChange}
                        name="pcountry"
                        value={admissioninfo.pcountry}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.state}
                        onChange={handleChange}
                        name="pstate"
                        value={admissioninfo.pstate}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.city}
                        onChange={handleChange}
                        name="ppcity"
                        value={admissioninfo.ppcity}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        disabled={checked}
                        label={curLanguage.pincode}
                        onChange={handleChange}
                        name="ppincode"
                        value={admissioninfo.ppincode}
                        fullWidth
                        margin="normal"
                        required
                        className={styles.customWidth}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    name=""
                    color="secondaryColor"
                    onClick={handlenextClick}
                    className="btn"
                    sx={{ display: "flex", minWidth: "90px !important" }}
                  >
                    {curLanguage.previous}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondaryColor"
                    className="btn"
                    sx={{ display: "flex", minWidth: "90px !important" }}
                    onClick={handleClickOpen}
                  >
                    {curLanguage.submit}
                  </Button>
                </Box>
              </TabPanel>
              {/* <Dialog open={open}>
              <DialogTitle
                id="responsive-dialog-title"
                sx={{
                  textAlign: "right",
                  color: "popup.popupheadertextcolor",
                  mb: 4,
                  justifyContent: "end",
                  position: "absolute",
                  right: "0",
                }}
              >
                <Popupclose close={handleClose} />
              </DialogTitle>
              <DialogContent>
                <Typography variant="h5" p={5} textAlign="center">
                  <Typography variant="h5" p={0} mb={2} textAlign="center">
                    Admission number: <b>{randomNum}</b>
                  </Typography>
                  Details sent on registered email.
                </Typography>
              </DialogContent>
            </Dialog> */}
            </Box>
            {/* tabs section end here */}
          </Box>
        </form>
      </Box>
    </MainCard>
  );
}

export default AdmissionForm;
