// import React, { useEffect, useState } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Box,
//   TextField,
//   IconButton,
//   Paper,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import axios from "axios";
// import { PrimaryBgBtn } from "../../../_Components/Common/Buttons/CustomBtns";


// const StudentList = (props) => {
//   const reciveSdata = props.studentData

//   const class_section = props.class_section
//   console.log(class_section, reciveSdata);
//   const classReceive = class_section[0].stdClass

//   const SectonReceive = class_section[0].stdsection
//   const academic_year = props.academic_year
//   console.log(academic_year);

//   const [students, setStudents] = useState(reciveSdata);
//   const [open, setOpen] = useState(false);
//   const [selectedStudent, setSelectedStudent] = useState(null);
//   const userinfo = JSON.parse(localStorage.getItem("userInfo"));
//   const nToken = localStorage.getItem("nToken");
//   console.log(students);


//   const handleOpen = (student) => {
//     setSelectedStudent(student);
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedStudent(null);
//   };
//   const handleMarksChange = (term, subjectId, newMarks) => {
//     setSelectedStudent((prevStudent) => {
//       const updatedGrades = { ...prevStudent.grades };
//       updatedGrades[term] = updatedGrades[term].map((subject) =>
//         subject.subjectid === subjectId
//           ? { ...subject, subject_marks: Number(newMarks) }
//           : subject
//       );

//       return { ...prevStudent, grades: updatedGrades };
//     });
//   };

//   useEffect(() => {
//     setStudents([...reciveSdata]); // Shallow copy ensures immutability
//   }, [reciveSdata]);

//   const handleSave = () => {
//     setStudents((prevStudents) =>
//       prevStudents.map((student) =>
//         student.studentid === selectedStudent.studentid
//           ? selectedStudent
//           : student

//       )
//     );
//     function addOrReplaceStudent(students, selectedStudent) {
//       const index = students.findIndex(student => student.studentid === selectedStudent.studentid);
//       if (index !== -1) {
//         students[index] = selectedStudent;
//       } else {
//         students.push(selectedStudent);
//       }
//     }
//     addOrReplaceStudent(students, selectedStudent);
//     console.log(students);
//     const insertData = {
//       added_by: "1",
//       class: classReceive,
//       section: SectonReceive,
//       school_code: userinfo[0]?.institution,
//       studentData: students,
//       academic_year: academic_year
//     }
//     console.log(insertData);
//     const insertDataURL = `${process.env.REACT_APP_nodewsPrefix}/addMarksReports`
//     axios.post(insertDataURL, insertData, {
//       headers: {
//         Authorization: `Bearer ${nToken}`,
//       }
//     }).then(response => {
//       console.log(response.data, "raj111");
//     }).catch(err => {
//       console.log(err);
//     });
//     handleClose();

//   };

//   const getSubjectComparison = (term1, term2) => {
//     const comparison = [];
//     const subjectIds = [
//       ...new Set([
//         ...term1.map((item) => item.subjectid),
//         ...term2.map((item) => item.subjectid),
//       ]),
//     ];

//     subjectIds.forEach((subjectId) => {
//       const term1Subject = term1.find((item) => item.subjectid === subjectId);
//       const term2Subject = term2.find((item) => item.subjectid === subjectId);
//       comparison.push({
//         subjectId,
//         subject_name: term1Subject?.subject_name || term2Subject?.subject_name || "Subject",
//         term1Marks: term1Subject?.subject_marks || "",
//         term2Marks: term2Subject?.subject_marks || "",
//       });
//     });

//     return comparison;
//   };

//   const columns = [
//     { field: "name", headerName: "Student Name", flex: 1 },
//     {
//       field: "actions",
//       headerName: "View Report",
//       flex: 0.5,
//       renderCell: (params) => (
//         <IconButton color="primary" onClick={() => handleOpen(params.row)}>
//           <VisibilityIcon />
//         </IconButton>
//       ),
//     },
//   ];

//   return (
//     <>

//       <DataGrid
//         rows={students && students.map((student) => ({
//           id: student.studentid,
//           ...student,
//         }))}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//         disableSelectionOnClick
//       />

//       {/* Dialog for Report Card */}
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>Report Card - {selectedStudent?.name}</DialogTitle>
//         <DialogContent>
//           {/* {selectedStudent && (
//             <Box>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Subject</th>
//                     <th>Term 1 Marks</th>
//                     <th>Term 2 Marks</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {getSubjectComparison(
//                     selectedStudent.grades.Term1,
//                     selectedStudent.grades.Term2
//                   ).map((subject) => (
//                     <tr key={subject.subjectId}>
//                       <td>{subject.subject_name}</td>
//                       <td>
//                         <TextField
//                           value={subject.term1Marks}
//                           onChange={(e) =>
//                             handleMarksChange(
//                               "Term1",
//                               subject.subjectId,
//                               e.target.value
//                             )
//                           }
//                           size="small"
//                           type="number"
//                         />
//                       </td>
//                       <td>
//                         <TextField
//                           value={subject.term2Marks}
//                           onChange={(e) =>
//                             handleMarksChange(
//                               "Term2",
//                               subject.subjectId,
//                               e.target.value
//                             )
//                           }
//                           size="small"
//                           type="number"
//                         />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               <Box display="flex" justifyContent="flex-end" marginTop="20px">
//                 <IconButton color="primary" onClick={handleSave}>
//                   Save
//                 </IconButton>
//               </Box>
//             </Box>
//           )} */}
//           {selectedStudent && (
//             <Box>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Subject</th>
//                     {/* Dynamically render column headers for each term */}
//                     {Object.keys(selectedStudent.grades).map((term) => (
//                       <th key={term}>{term} Marks</th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {/* Dynamically compare subjects across terms */}
//                   {Object.values(selectedStudent.grades)[0]?.map((subject) => (
//                     <tr key={subject.subjectid}>
//                       <td>{subject.subject_name}</td>
//                       {/* Dynamically render input fields for marks in each term */}
//                       {Object.keys(selectedStudent.grades).map((term) => {
//                         const termData = selectedStudent.grades[term].find(
//                           (item) => item.subjectid === subject.subjectid
//                         );
//                         return (
//                           <td key={term}>
//                             <TextField
//                               value={termData?.subject_marks || ""}
//                               onChange={(e) =>
//                                 handleMarksChange(
//                                   term,
//                                   subject.subjectid,
//                                   e.target.value
//                                 )
//                               }
//                               size="small"
//                               type="number"
//                             />
//                           </td>
//                         );
//                       })}
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               <Box marginTop="20px">
//         <TextField
//           label="Remarks"
//           multiline
//           fullWidth
//           rows={4}
//           value={selectedStudent.remarks || ""}
//           onChange={(e) =>
//             setSelectedStudent((prevStudent) => ({
//               ...prevStudent,
//               remarks: e.target.value,
//             }))
//           }
//         />
//       </Box>
//               <Box display="flex" justifyContent="flex-end" marginTop="20px">
//                 <PrimaryBgBtn color="primary" onClick={handleSave}>
//                   Save
//                 </PrimaryBgBtn>
//               </Box>
//             </Box>
//           )}

//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };

// export default StudentList;
// import React, { useEffect, useState } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Box,
//   TextField,
//   IconButton,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import axios from "axios";
// import { PrimaryBgBtn } from "../../../_Components/Common/Buttons/CustomBtns";

// const StudentList = (props) => {
//   const reciveSdata = props.studentData;
//   const class_section = props.class_section;
//   const academic_year = props.academic_year;

//   const classReceive = class_section[0].stdClass;
//   const SectonReceive = class_section[0].stdsection;

//   const [students, setStudents] = useState(reciveSdata);
//   const [open, setOpen] = useState(false);
//   const [selectedStudent, setSelectedStudent] = useState(null);

//   const userinfo = JSON.parse(localStorage.getItem("userInfo"));
//   const nToken = localStorage.getItem("nToken");

//   // Calculate average marks for each term dynamically
//   const calculateAverage = (grades) => {
//     const subjects = Object.values(grades);
//     return subjects.map((termGrades) => {
//       const totalMarks = termGrades.reduce((sum, subject) => sum + parseFloat(subject.subject_marks || 0), 0);
//       const average = totalMarks / termGrades.length;
//       return average.toFixed(2);
//     });
//   };

//   // Add calculated averages to the student data
//   useEffect(() => {
//     const updatedStudents = reciveSdata.map((student) => {
//       const averages = calculateAverage(student.grades);
//       return {
//         ...student,
//         term1Average: averages[0],
//         term2Average: averages[1],
//       };
//     });
//     setStudents(updatedStudents);
//   }, [reciveSdata]);

//   const handleOpen = (student) => {
//     setSelectedStudent(student);
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedStudent(null);
//   };

//   const handleMarksChange = (term, subjectId, newMarks) => {
//     setSelectedStudent((prevStudent) => {
//       const updatedGrades = { ...prevStudent.grades };
//       updatedGrades[term] = updatedGrades[term].map((subject) =>
//         subject.subjectid === subjectId
//           ? { ...subject, subject_marks: Number(newMarks) }
//           : subject
//       );
//       return { ...prevStudent, grades: updatedGrades };
//     });
//   };

//   const handleSave = () => {
//     const updatedStudents = students.map((student) =>
//       student.studentid === selectedStudent.studentid
//         ? selectedStudent
//         : student
//     );

//     setStudents(updatedStudents);

//     const insertData = {
//       added_by: "1",
//       class: classReceive,
//       section: SectonReceive,
//       school_code: userinfo[0]?.institution,
//       studentData: updatedStudents,
//       academic_year: academic_year,
//     };

//     axios
//       .post(`${process.env.REACT_APP_nodewsPrefix}/addMarksReports`, insertData, {
//         headers: {
//           Authorization: `Bearer ${nToken}`,
//         },
//       })
//       .then((response) => {
//         console.log(response.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });

//     handleClose();
//   };

//   const columns = [
//     { field: "name", headerName: "Student Name", flex: 1 },
//     { field: "term1Average", headerName: "Term 1 Average", flex: 0.5 },
//     { field: "term2Average", headerName: "Term 2 Average", flex: 0.5 },
//     {
//       field: "actions",
//       headerName: "View Report",
//       flex: 0.5,
//       renderCell: (params) => (
//         <IconButton color="primary" onClick={() => handleOpen(params.row)}>
//           <VisibilityIcon />
//         </IconButton>
//       ),
//     },
//   ];

//   return (
//     <>
//       <DataGrid
//         rows={students.map((student) => ({
//           id: student.studentid,
//           ...student,
//         }))}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//         disableSelectionOnClick
//       />

//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle>Report Card - {selectedStudent?.name}</DialogTitle>
//         <DialogContent>
//           {selectedStudent && (
//             <Box>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Subject</th>
//                     {Object.keys(selectedStudent.grades).map((term) => (
//                       <th key={term}>{term} Marks</th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {Object.values(selectedStudent.grades)[0]?.map((subject) => (
//                     <tr key={subject.subjectid}>
//                       <td>{subject.subject_name}</td>
//                       {Object.keys(selectedStudent.grades).map((term) => {
//                         const termData = selectedStudent.grades[term].find(
//                           (item) => item.subjectid === subject.subjectid
//                         );
//                         return (
//                           <td key={term}>
//                             <TextField
//                               value={termData?.subject_marks || ""}
//                               onChange={(e) =>
//                                 handleMarksChange(
//                                   term,
//                                   subject.subjectid,
//                                   e.target.value
//                                 )
//                               }
//                               size="small"
//                               type="number"
//                             />
//                           </td>
//                         );
//                       })}
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               <Box marginTop="20px">
//                 <PrimaryBgBtn color="primary" onClick={handleSave}>
//                   Save
//                 </PrimaryBgBtn>
//               </Box>
//             </Box>
//           )}
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };

// export default StudentList;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { PrimaryBgBtn } from "../../../_Components/Common/Buttons/CustomBtns";

const StudentList = (props) => {
  const reciveSdata = props.studentData;
  const class_section = props.class_section;
  const classReceive = class_section[0].stdClass;
  const SectonReceive = class_section[0].stdsection;
  const academic_year = props.academic_year;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");

  const [students, setStudents] = useState(reciveSdata);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleOpen = (student) => {
    setSelectedStudent(student);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStudent(null);
  };

  const handleMarksChange = (term, subjectId, newMarks) => {
    setSelectedStudent((prevStudent) => {
      const updatedGrades = { ...prevStudent.grades };
      updatedGrades[term] = updatedGrades[term].map((subject) =>
        subject.subjectid === subjectId
          ? { ...subject, subject_marks: Number(newMarks) }
          : subject
      );

      return { ...prevStudent, grades: updatedGrades };
    });
  };

  useEffect(() => {
    setStudents([...reciveSdata]); // Shallow copy ensures immutability
  }, [reciveSdata]);
  const handleSave = () => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.studentid === selectedStudent.studentid
          ? selectedStudent
          : student
      )
    );
    console.log(students,selectedStudent, "student");



    function addOrReplaceStudent(students, selectedStudent) {
      console.log(students);
      
      const index = students.findIndex(student => student.studentid === selectedStudent.studentid);
      if (index !== -1) {
        students[index] = selectedStudent;
      } else {
        students.push(selectedStudent);
      }
    }
    addOrReplaceStudent(students, selectedStudent);
    console.log(students);
    const insertData = {
      added_by: "1",
      class: classReceive,
      section: SectonReceive,
      school_code: userinfo[0]?.institution,
      studentData: students,
      academic_year: academic_year,
    };
    console.log(students, "student");
    const insertDataURL = `${process.env.REACT_APP_nodewsPrefix}/addMarksReports`;
    axios
      .post(insertDataURL, insertData, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    handleClose();
  };

  useEffect(() => {
    if (reciveSdata.length > 0) {
      const termKeys = Object.keys(reciveSdata[0].grades || {});
      const dynamicColumns = termKeys.map((term) => ({
        field: `${term} Grades`,
        headerName: `${term.charAt(0).toUpperCase() + term.slice(1)} Average`,
        flex: 0.5,
        valueGetter: (params) => params.row[`${term}_average`] || "N/A",
      }));

      const staticColumns = [
        { field: "name", headerName: "Student Name", flex: 1 },
        {
          field: "actions",
          headerName: "View Report",
          flex: 0.5,
          renderCell: (params) => (
            // <IconButton color="primary" onClick={() => handleOpen(params.row)}>
            //   <VisibilityIcon />
            // </IconButton>
            <PrimaryBgBtn color="primary" onClick={() => handleOpen(params.row)}>View Report</PrimaryBgBtn>
          ),
        },
      ];

      setColumns([...staticColumns, ...dynamicColumns]);
    }
  }, [reciveSdata]);

  return (
    <>
      <DataGrid
        rows={students.map((student) => ({
          id: student.studentid,
          ...student,
        }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />

      {/* Dialog for Report Card */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Report Card - {selectedStudent?.name}</DialogTitle>
        <DialogContent>
          {selectedStudent && (
            <Box>
              <table>
                <thead>
                  <tr>
                    <th>Subject</th>
                    {Object.keys(selectedStudent.grades).map((term) => (
                      <th key={term}>{term} Marks</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.values(selectedStudent.grades)[0]?.map((subject) => (
                    <tr key={subject.subjectid}>
                      <td>{subject.subject_name}</td>
                      {Object.keys(selectedStudent.grades).map((term) => {
                        const termData = selectedStudent.grades[term].find(
                          (item) => item.subjectid === subject.subjectid
                        );
                        return (
                          <td key={term}>
                            <TextField
                              value={termData?.subject_marks || ""}
                              onChange={(e) =>
                                handleMarksChange(
                                  term,
                                  subject.subjectid,
                                  e.target.value
                                )
                              }
                              size="small"
                              type="number"
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <Box marginTop="20px">
                <TextField
                  label="Remarks"
                  multiline
                  fullWidth
                  rows={4}
                  value={selectedStudent?.remarks || ""}
                  onChange={(e) =>
                    setSelectedStudent((prevStudent) => ({
                      ...prevStudent,
                      remarks: e.target.value,
                    }))
                  }
                />
              </Box> */}
              <Box display="flex" justifyContent="flex-end" marginTop="20px">
                <PrimaryBgBtn color="primary" onClick={handleSave}>
                  Save
                </PrimaryBgBtn>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StudentList;
