import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { IoTrashOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import "./SettingPage.scss";
import Loader from "../Utils/loader/Loader";
import { Scrollbar } from "react-scrollbars-custom";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { CustomWidthTooltip } from "../Common/CustomWidthTooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";

const DevPermission = ({ selectedSchool, schoolName }) => {
  const [permissions, setPermissions] = useState();
  const [availDepartment, setAvailDepartment] = useState();
  const nToken = localStorage.getItem("nToken");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [editValues, setEditValues] = useState({
    role: "",
    department: "",
    section: "",
    additionalInfo: "",
  });
  const [newSection, setNewSection] = useState({
    role: "",
    department: "",
    section: "",
    additionalInfo: "",
    read: false,
    write: false,
    canAccess: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getSchoolConfigData`,
          {
            schoolcode: selectedSchool,
          }
        );
        setPermissions(response.data.info);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchData();
  }, [selectedSchool]);
  
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getDepartment?schoolcode=${selectedSchool}`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        setAvailDepartment(response.data.department);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchDepartment();
  }, []);

  const handlePermissionChange = (role, section, permissionType) => {
    setPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [section]: {
            ...prevPermissions[role][section],
            [permissionType]: !prevPermissions[role][section][permissionType],
          },
        },
      };
      return newPermissions;
    });
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    const datas = JSON.stringify(permissions);
    try {
      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
        {
          permission: datas,
          schoolcode: selectedSchool,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      alert("Updated Successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  const handleAddSection = () => {
    setPermissions((prevPermissions) => {
      const { role, department, section, additionalInfo } = newSection;

      const newPermissions = {
        ...prevPermissions,
        [department]: {
          ...(prevPermissions[department] || {}),
          [section]: {
            ...(prevPermissions[department]?.[section] || {}),
            additionalInfo,
            read: false,
            write: false,
            canAccess: false,
          },
        },
      };

      return newPermissions;
    });
    setOpen(false);
    setNewSection({
      role: "",
      department: "",
      section: "",
      additionalInfo: "",
      read: false,
      write: false,
      canAccess: false,
    });
  };

  const closeAddSection = () => {
    setOpen(false);
    setNewSection({
      role: "",
      department: "",
      section: "",
      additionalInfo: "",
    });
  };

  const handleDeleteSection = (section, department) => {
    setPermissions((prevPermissions) => {
      const newPermissions = { ...prevPermissions };
      delete newPermissions[department][section];
      return newPermissions;
    });
  };

  const handleDeleteRole = (role) => {
    setPermissions((prevPermissions) => {
      const newPermissions = { ...prevPermissions };
      delete newPermissions[role];
      return newPermissions;
    });
  };

  const handleEditClick = (role, section, additionalInfo) => {
    setEditMode({ role, section });
    setEditValues({ role, section, additionalInfo });
  };

  const handleEditChange = (field, value) => {
    setEditValues((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleSaveEdit = () => {
    const { role, section, additionalInfo } = editValues;

    setPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [section]: {
            ...prevPermissions[role][section],
            additionalInfo,
          },
        },
      };
      return newPermissions;
    });

    setEditMode(null);
    setEditValues({
      role: "",
      department: "",
      section: "",
      additionalInfo: "",
    });
  };

  const handleCancelEdit = () => {
    setEditMode(null);
    setEditValues({
      role: "",
      department: "",
      section: "",
      additionalInfo: "",
    });
  };

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          mt: 2,
          pt: 2,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={0}
        className="customCard setCard"
      >
        <Typography
          className="title"
          variant="h6"
          sx={{
            borderColor: "borderLine.main",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box className="titleHead">
            <b>{schoolName?.name}</b>
          </Box>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add Fields
          </Button>
        </Typography>
        {!permissions ? (
          <Box>
            <Loader />
          </Box>
        ) : (
          <Card className="customCardContent settingCard">
            <form onSubmit={handleFormData}>
              <CardContent className="cardContent" sx={{ p: 0 }}>
                <Scrollbar
                  style={{ width: "100%", height: "calc(60vh - 100px)" }}
                  className="customCardItem"
                >
                  {Object.keys(permissions).map((role, index) => (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      alignItems={"center"}
                    >
                      <Grid item md={11}>
                        <Accordion className="customAccordion">
                          <AccordionSummary
                            expandIcon={<GridExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography
                              variant="h6"
                              sx={{ textTransform: "capitalize" }}
                            >
                              <b>{role === "pedagogy" ? "Teacher" : role}</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              {Object.keys(permissions[role]).map((section) => (
                                <Grid key={section} item md={3} sm={6} xs={6}>
                                  <Box className="componentArea">
                                    <Box className="boxInfo">
                                      <Typography
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {section}
                                      </Typography>
                                      <Box className="boxIcons">
                                        <CustomWidthTooltip
                                          title={
                                            permissions[role][section]
                                              .additionalInfo || section
                                          }
                                          placement="top-start"
                                          style={{
                                            width: "max-width",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <p>
                                            <IconButton>
                                              <AiOutlineInfoCircle size={28} />
                                            </IconButton>
                                          </p>
                                        </CustomWidthTooltip>
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteSection(section, role)
                                          }
                                        >
                                          <IoTrashOutline color="profileButton.main" />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleEditClick(
                                              role,
                                              section,
                                              permissions[role][section]
                                                .additionalInfo || ""
                                            )
                                          }
                                        >
                                          <AiOutlineEdit color="profileButton.main" />
                                        </IconButton>
                                      </Box>
                                    </Box>

                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      gap={2}
                                    >
                                      <label>
                                        Read:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].read
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "read"
                                            )
                                          }
                                        />
                                      </label>
                                      <label>
                                        Write:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].write
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "write"
                                            )
                                          }
                                        />
                                      </label>
                                      <label>
                                        CanAccess:&nbsp;
                                        <input
                                          type="checkbox"
                                          checked={
                                            permissions[role][section].canAccess
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              role,
                                              section,
                                              "canAccess"
                                            )
                                          }
                                        />
                                      </label>
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item md={1} className="roleBtns">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <CustomWidthTooltip
                            title={
                              'If a user selects "Read," they can access that page and view its contents.Additional features and functionalities become available if the user selects both "Read" and "Write," allowing them to not only view but also modify or interact with the content on that page.If "CanAccess" is selected, the user is granted permission to enter the page and utilize the specified features based on their read/write permissions.'
                            }
                            placement="left"
                            style={{
                              width: "max-width",
                              cursor: "pointer",
                            }}
                          >
                            <p>
                              <IconButton>
                                <AiOutlineInfoCircle size={28} />
                              </IconButton>
                            </p>
                          </CustomWidthTooltip>
                          <IconButton onClick={() => handleDeleteRole(role)}>
                            <IoTrashOutline />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Scrollbar>
              </CardContent>

              <CardActions className="settingSave">
                <Box textAlign="center" width={"100%"}>
                  <Button type="submit" variant="contained" className="btn">
                    Save
                  </Button>
                </Box>
              </CardActions>
            </form>
            <Box>
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add Department and Section</DialogTitle>
                <DialogContent>
                  <Select
                    fullWidth
                    labelid="department-label"
                    value={newSection.department}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        department: e.target.value,
                      })
                    }
                    label="Department"
                  >
                    Select Role
                    {console.log(availDepartment, "availDepartment")}
                    {availDepartment?.map((data, index) => (
                      <MenuItem value={data.toLowerCase()} key={index}>
                        {" "}
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    label="Section"
                    value={newSection.section}
                    onChange={(e) =>
                      setNewSection({ ...newSection, section: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Additional Info"
                    value={newSection.additionalInfo}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        additionalInfo: e.target.value,
                      })
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeAddSection}>Cancel</Button>
                  <Button onClick={handleAddSection}>Add Section</Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box>
              <Dialog open={Boolean(editMode)} onClose={handleCancelEdit}>
                <DialogTitle>Edit Section</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Role"
                    value={editValues.role}
                    onChange={(e) => handleEditChange("role", e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled
                  />
                  <TextField
                    label="Section"
                    value={editValues.section}
                    onChange={(e) =>
                      handleEditChange("section", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    disabled
                  />
                  <TextField
                    label="Additional Info"
                    value={editValues.additionalInfo}
                    onChange={(e) =>
                      handleEditChange("additionalInfo", e.target.value)
                    }
                    fullWidth
                    multiline
                    rows={3}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelEdit}>Cancel</Button>
                  <Button onClick={handleSaveEdit}>Save</Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Card>
        )}
      </Card>
    </Container>
  );
};

export default DevPermission;
