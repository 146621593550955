import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./CustomBreadCrumb.scss";

const CustomBreadCrumb = () => {
  const location = useLocation();
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  const [queryParams, setQueryParams] = useState({});
  const isHomeScreen = location.pathname === "/";
  const isDashboardScreen = location.pathname === "/dashboard";
  const forgotPasswordScreen = location.pathname === "/user-forgot-password";

  useEffect(() => {
    const storedParams = JSON.parse(localStorage.getItem("queryParams")) || {};
    setQueryParams(storedParams);

    const currentPath = location.pathname;
    const currentSearch = location.search;
    const updatedParams = { ...storedParams, [currentPath]: currentSearch };
    setQueryParams(updatedParams);
    localStorage.setItem("queryParams", JSON.stringify(updatedParams));
  }, [location.pathname, location.search]);

  const pathnames = location.pathname.split("/").filter((x) => x);

  const pathNamesMap = {
    GetCourseContent: "Courses",
    modAccessInformation: "modAccessInformation",
    allStudentsReport: "Progress Report",
    StudentReport: "Student Report",
    stdCertificate: "Certificate",
    teacherTimeTable: "Time Table",
    studentTimeTable: "Time Table",
  };

  const getDisplayName = (value, search) => {
    if (
      value === "GetCourseContent" &&
      userinfo[0]?.department.toLowerCase() === "pedagogy"
    ) {
      const params = new URLSearchParams(search);
      const stdclass = params.get("stdclass");
      const section = params.get("section");
      const subject = params.get("fullname");
      return `${subject}  ${stdclass} ${section}`;
    } else if (value === "modAccessInformation") {
      const params = new URLSearchParams(search);
      const title = params.get("title");
      return `${title}`;
    } else if (
      value === "GetCourseContent" &&
      userinfo[0]?.department.toLowerCase() === "student"
    ) {
      const params = new URLSearchParams(search);
      const subject = params.get("fullname");
      return `${subject} `;
    }
    return pathNamesMap[value] || value;
  };

  const upperCase = (str) => {
    const value = str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split("  ");
    return value.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  };

  return (
    <Container maxWidth="xl">
      <nav>
        <ul className="breadcrumb">
          {!isHomeScreen && !isDashboardScreen && !forgotPasswordScreen && (
            <>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            </>
          )}
          {isDashboardScreen && (
            <li>
              <Link to="/">Home</Link>
            </li>
          )}
          {forgotPasswordScreen && !isDashboardScreen && !isHomeScreen && (
            <li>
              <Link to="/">Home</Link>
            </li>
          )}

          {token &&
            pathnames.map((value, index) => {
              const toPath = `/${pathnames.slice(0, index + 1).join("/")}`;
              const to = `${toPath}${queryParams[toPath] || ""}`;
              const displayName = getDisplayName(
                value,
                queryParams[toPath] || ""
              );
              return (
                <li key={to}>
                  <Link to={to} className="activePage">
                    {upperCase(displayName)}
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>
    </Container>
  );
};

export default CustomBreadCrumb;
